import Image from 'next/image';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { FaGift } from 'react-icons/fa';
import { Input } from '../Input';
import Select from '../Input/select';
import { MemberCard, Col, StyledLabel, StyledButton } from './styles';
import {
  DeveloperRole,
  DeveloperExperience,
  EnglishLevel,
  WorkloadType,
  TimeGroup,
  CurrencyLocale,
} from '../../../types/TeamCalculator/Enums';
import TeamItem from '../../../types/TeamCalculator/TeamItem';
import {
  parseFormDataToType,
  filterValidRow,
  isEmptyRow,
  getCurrency,
  getCostByTimeGroup,
  getCurrencyWithFractional,
} from './utils';
import { calculateTeamItemCostPerMonth } from './utils/calculate';
import { Constants, imagesByRole } from './utils/consts';
import { useRouter } from 'next/router';
import Budget from '../../../types/TeamCalculator/Budget';

interface MemberFormType {
  update: (index: number, data: TeamItem) => void;
  index: number;
  field: TeamItem;
  remove: (index: number) => void;
  adjustmentRate?: number;
  currentConstants: Constants;
  timeGroup: keyof typeof TimeGroup.en;
  locked?: boolean;
  currencyLocale: CurrencyLocale;
  currency: Budget['currency'];
}

const MemberForm: React.FC<MemberFormType> = ({
  update,
  index,
  field,
  remove,
  adjustmentRate,
  currentConstants,
  timeGroup,
  locked,
  currencyLocale,
  currency,
}) => {
  const {
    register,
    control,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: field,
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const router = useRouter();
  const isEn = router.locale === 'en';

  const allWatch = useWatch<TeamItem>({
    control,
  });
  const currentRole = useWatch<TeamItem>({
    name: `role`,
    control,
  });

  const costLabel = {
    hourly: 'hour',
    daily: 'day',
    weekly: 'week',
    monthly: 'month',
  };

  const costLabelPT = {
    hourly: 'hora',
    daily: 'dia',
    weekly: 'semana',
    monthly: 'mês',
  };

  useEffect(() => {
    // This is set the new defaultValues and set "isDirty" to false
    reset(field);
  }, [field]);

  useEffect(() => {
    // Check if some update really happened
    // Manually checking role, since it's not making it dirty
    if (!isDirty && field.role === currentRole) return;

    const parsed = parseFormDataToType(allWatch);
    debugger;

    if (filterValidRow(parsed)) {
      // console.log('valid!', adjustmentRate);
      const monthlyCost = calculateTeamItemCostPerMonth(parsed, currentConstants, adjustmentRate);
      update(index, {
        ...parsed,
        currentCost: monthlyCost,
        quantity: parsed.quantity ?? 0,
      });
    } else if (!isEmptyRow(parsed)) {
      // console.log('not empty?', parsed);
      update(index, {
        ...parsed,
        currentCost: 0,
      });
    }
  }, [allWatch, currentRole]);
  console.log('currency', currencyLocale, currency);
  return (
    <MemberCard locked={allWatch.free || locked} className={allWatch.free ? 'free' : ''}>
      {allWatch.free && (
        <span className="ribbon">
          <FaGift /> {isEn ? 'COURTESY' : 'CORTESIA'}
        </span>
      )}
      <div className="form-body">
        <Image
          alt="member image"
          className="member-image"
          src={
            imagesByRole[
              (currentRole
                ? DeveloperRole.en[currentRole as keyof typeof DeveloperRole.en]
                : '') as keyof typeof DeveloperRole.en
            ]
          }
          width={100}
          height={100}
        />
        <Col>
          <StyledLabel>{isEn ? 'QTY' : 'QTD'}</StyledLabel>
          <Input
            disabled={allWatch.free || locked}
            type="number"
            min={1}
            {...register(`quantity` as const)}
          />
        </Col>
        <Col style={{ flexGrow: 2 }}>
          <StyledLabel>{isEn ? 'ROLE' : 'CARGO'}</StyledLabel>
          <Select
            disabled={allWatch.free || locked}
            {...register(`role` as const, { required: true })}
          >
            <option value={undefined}></option>
            {Object.keys(DeveloperRole.en).map((role) => (
              <option key={role} value={role}>
                {DeveloperRole[router.locale as 'pt' | 'en'][role as keyof typeof DeveloperRole.en]}
              </option>
            ))}
          </Select>
        </Col>
        <Col style={{ flexGrow: 1 }}>
          <StyledLabel>{isEn ? 'MINIMUM EXPERIENCE' : 'EXPERIÊNCIA MÍNIMA'}</StyledLabel>
          <Select disabled={allWatch.free || locked} {...register(`minimumExperience` as const)}>
            {/* <option value={''}></option> */}
            {Object.keys(DeveloperExperience.en).map((role) => (
              <option key={role} value={role}>
                {
                  DeveloperExperience[router.locale as 'pt' | 'en'][
                    role as keyof typeof DeveloperExperience.en
                  ]
                }
              </option>
            ))}
          </Select>
        </Col>
        <Col style={{ flexGrow: 1 }}>
          <StyledLabel>{isEn ? 'MINIMUM ENGLISH LEVEL' : 'NÍVEL MÍNIMO DE INGLÊS'}</StyledLabel>
          <Select disabled={allWatch.free || locked} {...register(`englishLevel` as const)}>
            {/* <option value={''}></option> */}
            {Object.keys(EnglishLevel.en).map((role) => (
              <option key={role} value={role}>
                {EnglishLevel[router.locale as 'pt' | 'en'][role as keyof typeof EnglishLevel.en]}
              </option>
            ))}
          </Select>
        </Col>
        <Col style={{ flexGrow: 2 }}>
          <StyledLabel>{isEn ? 'WORKLOAD' : 'CARGA HORÁRIA'}</StyledLabel>
          <Select disabled={allWatch.free || locked} {...register(`workloadType` as const)}>
            {/* <option value={''}></option> */}
            {Object.keys(WorkloadType.en).map((role) => (
              <option key={role} value={role}>
                {WorkloadType[router.locale as 'pt' | 'en'][role as keyof typeof WorkloadType.en]}
              </option>
            ))}
          </Select>
        </Col>

        {/* <Input {...register(`free` as const, { value: field.free })} hidden={false} /> */}
        {/* <ConditionField control={control} register={register} /> */}

        <div className={`member-cost ${allWatch.free ? 'free' : ''}`}>
          {!!field.currentCost && (
            <>
              <p>
                {timeGroup === 'hourly'
                  ? getCurrencyWithFractional(
                      currencyLocale,
                      currency,
                      getCostByTimeGroup(field.currentCost ?? 0, field.workloadType, timeGroup),
                    )
                  : getCurrency(
                      currencyLocale,
                      currency,
                      getCostByTimeGroup(field.currentCost ?? 0, field.workloadType, timeGroup),
                    )}
                <span>/{isEn ? costLabel[timeGroup] : costLabelPT[timeGroup] || ''}</span>
              </p>
              {allWatch.free && <p>{isEn ? 'Not charged' : 'Não cobrado'}</p>}
            </>
          )}
        </div>
      </div>
      {allWatch.free || locked || (
        <StyledButton className="hover-button" onClick={() => remove(index)} />
      )}
    </MemberCard>
  );
};

export default MemberForm;
