import React, { useMemo, useRef, useState } from 'react';
import { uniqueId } from 'lodash';
import { useEffect } from 'react';
import { FaArrowDown } from 'react-icons/fa';
import Button from '../Button';
import Select from '../Input/select';
import {
  calculateTeamCost,
  calculateTeamItemCostPerMonth,
  getDiscountForProjectLength,
} from './utils/calculate';
import {
  Container,
  Col,
  SummaryCard,
  Row,
  StyledComment,
  StyledSummaryTable,
  StyledSelectContainer,
  StyledLabel,
  LoginWrapper,
} from './styles';
import {
  DeveloperExperience,
  EnglishLevel,
  WorkloadType,
  ContractLength,
  TimeGroup,
  CurrencyLocale,
} from '../../../types/TeamCalculator/Enums';
import TeamItem from '../../../types/TeamCalculator/TeamItem';
import { Constants, weeksInMonth } from './utils/consts';
import { useRouter } from 'next/router';
import {
  createOrUpdateItem,
  deleteItem,
  setDocumentListener,
  setListListener,
} from '../../../utils/firebase';
import { Timestamp, Unsubscribe } from 'firebase/firestore';
import Budget from '../../../types/TeamCalculator/Budget';
import {
  filterValidRow,
  getHoursByWorkloadType,
  getCurrency,
  getCurrencyWithFractional,
} from './utils';
import MemberForm from './MemberForm';
import { formatTimestamp } from '../../../utils/date';
import theme from '../../../styles/theme';
import { BsCheckCircle } from 'react-icons/bs';
import { AiOutlineCloseSquare } from 'react-icons/ai';
import { Input } from '../Input';
import { LoadingIcon } from '../LoadingIcon';
import ClientComponent from '../ClientComponent/ClientComponent';

interface TeamCalculatorType {
  currentConstants: Constants;
  currentBudget: Budget;
}

const TeamCalculator: React.FC<TeamCalculatorType> = ({ currentConstants, currentBudget }) => {
  const [currentTeam, setCurrentTeam] = useState<TeamItem[]>([]);

  const [adjustmentRate, setAdjustmentRate] = useState<number>(1);

  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [totalHours, setTotalHours] = useState<number>(0);

  const [selectedTimeGroup, setSelectedTimeGroup] = useState<keyof typeof TimeGroup.en>('monthly');

  const [contractPeriod, setContractPeriod] = useState<keyof typeof ContractLength>('three');
  const [totalCostPerMonth, setTotalCostPerMonth] = useState<number>(0);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [currentDiscount, setCurrentDiscount] = useState<number>(0);
  const [finalValue, setFinalValue] = useState<number>(0);
  const [finalValuePerMonth, setFinalValuePerMonth] = useState<number>(0);

  const router = useRouter();
  const isEn = router.locale === 'en';

  const hoursPerMonth = useMemo(() => {
    if (!totalHours || !contractPeriod) return 0;
    return totalHours / Number(ContractLength[contractPeriod]);
  }, [totalHours, contractPeriod]);

  const showSummary = useMemo(() => {
    const minimumHours = Number(currentBudget.minimumHours || currentConstants.minimumHours);

    // Dot not limit if minimumHours is not set
    if (!minimumHours) return true;

    if (totalHours >= minimumHours) return true;
    return false;
  }, [totalHours, currentBudget.minimumHours, currentConstants.minimumHours]);

  const locked = useMemo(
    () => !!currentBudget.locked || !!currentBudget.acceptedAt || !!currentBudget.declinedAt,
    [currentBudget],
  );

  const currencyLocale: CurrencyLocale = useMemo(
    () => CurrencyLocale[currentBudget.currency || 'usd'],
    [currentBudget],
  );

  const append = async () => {
    const newTeamItem: TeamItem = {
      quantity: 1,
      minimumExperience: 'junior',
      englishLevel: 'none',
      workloadType: Object.keys(
        WorkloadType[router.locale as 'pt' | 'en'],
      )[0] as keyof typeof WorkloadType.en,
      localId: uniqueId(),
      createdAt: Timestamp.fromDate(new Date()),
      free: false,
    };

    // console.log('newTeamItem', newTeamItem, currentBudget);
    if (currentBudget) {
      // console.log('with currentBudget');

      await createOrUpdateItem(['budgets', currentBudget.id, 'members'], newTeamItem);
      // console.log('serverItem', serverItem);
    } else {
      console.log('no currentBudget');
      const newTeam = [...currentTeam, newTeamItem];
      setCurrentTeam(newTeam);
    }
  };

  const remove = async (index: number) => {
    const toBeRemoved = currentTeam[index];
    const filtered = currentTeam.filter((_, i) => i !== index);

    if (currentBudget && toBeRemoved.id)
      await deleteItem(['budgets', currentBudget.id, 'members'], toBeRemoved.id);
    else {
      setCurrentTeam(filtered);
    }
  };

  const update = async (index: number, data: TeamItem) => {
    // save update to firebase
    const newTeam = currentTeam.map((field, i) => {
      if (i === index) {
        return data;
      }
      return field;
    });

    if (currentBudget && data.id) {
      await createOrUpdateItem(['budgets', currentBudget.id, 'members'], data, data.id);
    } else {
      setCurrentTeam(newTeam);
    }
  };

  const updateContractPeriod = async (newPeriod: keyof typeof ContractLength) => {
    // save update to firebase
    if (currentBudget) {
      const data = currentBudget.minimumHours
        ? { contractPeriod: newPeriod }
        : {
            contractPeriod: newPeriod,
            minimumHours: currentConstants.minimumHours || 0,
          };
      await createOrUpdateItem(['budgets'], data, currentBudget.id, true);
    } else {
      setContractPeriod(newPeriod);
    }
  };

  const unsubTeam = useRef<null | Unsubscribe>(null);
  // ! Set Members Listener
  useEffect(() => {
    if (currentBudget) {
      setAdjustmentRate(currentBudget.adjustmentRate ?? 1);
      setContractPeriod(currentBudget.contractPeriod ?? 'six');
    }
  }, [currentBudget.adjustmentRate, currentBudget.contractPeriod]);

  useEffect(() => {
    if (currentBudget) {
      setListListener<TeamItem>(
        ['budgets', currentBudget?.id, 'members'],
        setCurrentTeam,
        unsubTeam,
      );
    }
    return () => {
      if (unsubTeam.current) {
        console.log('Unsubscribing');
        unsubTeam.current();
      }
    };
  }, [currentBudget?.id]);
  // ! In case the adjustment rate changes, recalculate everything
  useEffect(() => {
    // Update every team value
    const newTeam = currentTeam.map((field) => {
      const monthlyCost = calculateTeamItemCostPerMonth(field, currentConstants, adjustmentRate);
      return { ...field, currentCost: monthlyCost };
    });
    if (currentBudget) {
      newTeam.map((t) =>
        createOrUpdateItem(['budgets', currentBudget.id, 'members'], t, t.id, true),
      );
    } else {
      setCurrentTeam(newTeam);
    }
  }, [adjustmentRate, currentConstants]);

  // ! On every team or contract period change, recalculate totals
  useEffect(() => {
    // Get only valid rows which are not Free
    const validTeam = currentTeam.filter((c) => filterValidRow(c, true));
    // console.log('validTeam', validTeam);

    const quantity = validTeam.reduce((acc, cur) => acc + (cur.quantity ?? 0), 0);
    // console.log('validTeam', quantity);
    if (quantity !== totalQuantity) setTotalQuantity(quantity);

    const hours = validTeam.reduce(
      (acc, cur) =>
        acc +
        (cur.workloadType
          ? getHoursByWorkloadType(
              WorkloadType.en[cur.workloadType] as keyof typeof WorkloadType.en,
              true,
            ) *
            weeksInMonth *
            cur.quantity
          : 0),
      0,
    );
    const numberOfMonths = parseInt(ContractLength[contractPeriod]);

    setTotalHours(hours * numberOfMonths);

    const newTotalCost = calculateTeamCost(validTeam, currentConstants, adjustmentRate);
    // console.log('newTotalCost', newTotalCost);
    setTotalCostPerMonth(newTotalCost);

    const discountForPeriod = getDiscountForProjectLength(contractPeriod, currentConstants);
    // console.log('discountForPeriod', discountForPeriod);
    setCurrentDiscount(discountForPeriod);

    setTotalCost(newTotalCost * numberOfMonths);
  }, [currentTeam, contractPeriod]);

  // ! On every total cost or discount change, recalculate final value
  useEffect(() => {
    const discountForPeriod = getDiscountForProjectLength(contractPeriod, currentConstants);
    const newFinalValue = totalCost - (discountForPeriod * totalCost) / 100;
    setFinalValue(newFinalValue);

    const newFinalValuePerMonth = newFinalValue / parseInt(ContractLength[contractPeriod]);
    setFinalValuePerMonth(newFinalValuePerMonth);
  }, [totalCost, currentDiscount]);

  useEffect(() => {
    // Save the new values on the Firebase
    createOrUpdateItem(
      ['budgets'],
      {
        monthlyInvestment: totalCostPerMonth,
        contractMonthlyInvestment: finalValuePerMonth,
        contractTotalInvestment: finalValue,
      },
      currentBudget?.id,
      true,
    );
  }, [totalCostPerMonth, finalValue, finalValuePerMonth]);

  return (
    <Container>
      <h1>
        {isEn
          ? `Build a new ${currentBudget.title} team`
          : `Formar um novo time ${currentBudget.title}`}
      </h1>
      {/* <h2>{currentBudget.title}</h2>
      <h3>{currentBudget.adjustmentRate}</h3> */}
      <form>
        {currentTeam
          .sort((a, b) => (a.role && b.role ? a.role.localeCompare(b.role) : 0))
          .sort((a, b) => (a.free ? 1 : b.free ? -1 : 0))
          .map((field, index) => {
            return (
              <MemberForm
                key={field.id}
                index={index}
                field={field}
                remove={remove}
                update={update}
                timeGroup={selectedTimeGroup}
                adjustmentRate={adjustmentRate}
                currentConstants={currentConstants}
                locked={locked}
                currencyLocale={currencyLocale}
                currency={currentBudget.currency}
              />
            );
          })}
      </form>

      {!currentTeam.some((m) => !m.free) && (
        <StyledComment>
          <FaArrowDown />
          {isEn
            ? ' Start adding team members to get started'
            : ' Comece a adicionar novos membros no time para começar'}
        </StyledComment>
      )}

      <Row className="button-row">
        {!locked && (
          <Button variant="tertiary" type="button" onClick={() => append()}>
            {isEn ? 'Add Team Member' : 'Adicionar um novo membro no time'}
          </Button>
        )}
        {currentTeam.some((m) => !m.free && !!m.role) && (
          <StyledSelectContainer>
            <StyledLabel>{isEn ? 'VIEW COST PER TIME' : 'Ver o custo por tempo'}</StyledLabel>
            <Select
              value={selectedTimeGroup}
              onChange={(e) => setSelectedTimeGroup(e.target.value as keyof typeof TimeGroup.en)}
            >
              <option value="monthly">{isEn ? 'Monthly' : 'Mensal'}</option>
              <option value="weekly">{isEn ? 'Weekly' : 'Semanal'}</option>
              <option value="daily">{isEn ? 'Daily' : 'Diário'}</option>
              <option value="hourly">{isEn ? 'Hourly' : 'Por hora'}</option>
            </Select>
          </StyledSelectContainer>
        )}
      </Row>
      {currentBudget.acceptedAt && (
        <Row className="accepted-row">
          <Col>
            <h2>
              {isEn ? 'Accepted' : 'Aceito'} <BsCheckCircle color={theme.colors.green} />
            </h2>
            <p>
              {isEn ? 'Budget proposal accepted at' : 'Proposta de orçamento aceita em'}{' '}
              {formatTimestamp(currentBudget.acceptedAt)}
            </p>
          </Col>
          <hr />
        </Row>
      )}
      {currentBudget.declinedAt && (
        <Row className="accepted-row">
          <Col>
            <h2>
              {isEn ? 'Declined' : 'Rejeitado'} <AiOutlineCloseSquare color={theme.colors.red} />
            </h2>
            <p>
              {isEn ? 'Budget proposal declined at' : 'Proposta de orçamento rejeitada em'}{' '}
              {formatTimestamp(currentBudget.declinedAt)}
            </p>
          </Col>
          <hr />
        </Row>
      )}

      {currentTeam.some((m) => !m.free && !!m.role) && showSummary && (
        <Row className="summary-row">
          <Col>
            <h2>{isEn ? 'Summary' : 'Sumário'}</h2>
            <SummaryCard variant="primary" fixedWidth={420}>
              <div className="value-container">
                <p>
                  {isEn ? 'HOURLY RATE (AVERAGE)' : 'TAXA COBRADA POR HORA (MÉDIA)'}
                  <br />
                  <span>
                    {getCurrencyWithFractional(
                      currencyLocale,
                      currentBudget.currency,
                      totalCostPerMonth / hoursPerMonth,
                    )}
                    <span className="text">{isEn ? '/hour' : '/hora'}</span>
                    <span className="hours-month">
                      {' '}
                      - {hoursPerMonth}{' '}
                      <span className="text">{isEn ? 'hours/month' : 'horas/mês'}</span>
                    </span>
                  </span>
                </p>
              </div>
              <div className="value-container">
                <p>
                  {isEn ? 'MONTHLY INVESTMENT' : 'INVESTIMENTO MENSAL'}
                  <br />
                  <span>
                    {getCurrency(currencyLocale, currentBudget.currency, totalCostPerMonth)}
                    <span className="text">{isEn ? '/month' : '/mês'}</span>
                  </span>
                </p>
              </div>
              <hr />
              <div className="value-container">
                <label>{isEn ? 'CONTRACT PERIOD' : 'PERÍODO DE CONTRATO'}</label>
                <div className="select-wrapper">
                  <Select
                    value={contractPeriod}
                    disabled={locked}
                    onChange={(e) =>
                      // setContractPeriod(e.target.value as keyof typeof ContractLength)
                      updateContractPeriod(e.target.value as keyof typeof ContractLength)
                    }
                  >
                    {Object.keys(ContractLength).map((key) => (
                      <option key={key} value={key}>
                        {ContractLength[key as keyof typeof ContractLength]}{' '}
                        {isEn ? 'months' : 'meses'}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="value-container">
                <p>
                  {isEn ? 'LONG TERM CONTRACT DISCOUNT' : 'DESCONTO PARA CONTRATO A LONGO PRAZO'}
                </p>
                {currentDiscount === 0 ? (
                  <p className="discount-info">
                    {isEn
                      ? 'Longer contracts will give you discounts'
                      : 'Contratos a longo prazo concedem descontos maiores'}
                  </p>
                ) : (
                  <span className="discount">
                    <span>{currentDiscount}%</span> -{' '}
                    {getCurrency(
                      currencyLocale,
                      currentBudget.currency,
                      (currentDiscount * totalCost) / 100,
                    )}
                  </span>
                )}
              </div>
              <div className="value-container">
                <p>{isEn ? 'FINAL INVESTMENT' : 'INVESTIMENTO FINAL'}</p>
                {/* {currentDiscount > 0 && <p>{usd.format(totalCost)}</p>} */}
                <p className="month">
                  {getCurrency(currencyLocale, currentBudget.currency, finalValuePerMonth)}
                  <span>{isEn ? '/month' : '/mês'}</span>
                </p>
                <p className="contract">
                  {getCurrency(currencyLocale, currentBudget.currency, finalValue)}
                  {isEn ? '/contract' : '/contrato'}
                </p>
                <p className="hour">
                  {getCurrencyWithFractional(
                    currencyLocale,
                    currentBudget.currency,
                    finalValuePerMonth / hoursPerMonth,
                  )}
                  {isEn ? '/hour' : '/hora'}
                </p>
              </div>
            </SummaryCard>
          </Col>

          <Col>
            <h2>{isEn ? 'Price Details' : 'Detalhes de Preço'}</h2>
            <SummaryCard variant="secondary" fixedWidth={480}>
              <StyledSummaryTable>
                <h3>{isEn ? 'Professional Level' : 'Nível Profissional'}</h3>
                {Object.entries(DeveloperExperience[router.locale as 'en' | 'pt']).map((value) => {
                  const key = value[0] as keyof typeof DeveloperExperience.en;
                  if (currentConstants.discountPercentByExperience[key] === 0) return null;
                  return (
                    <div key={key}>
                      <span className="name">{value[1]}</span>
                      <hr />
                      <span className="value plus">
                        +{currentConstants.discountPercentByExperience[key]}%
                      </span>
                    </div>
                  );
                })}
              </StyledSummaryTable>
              <StyledSummaryTable>
                <h3>{isEn ? 'English Level' : 'Nível de Inglês'}</h3>
                {Object.entries(EnglishLevel[router.locale as 'pt' | 'en']).map((value) => {
                  const key = value[0] as keyof typeof EnglishLevel.en;
                  if (currentConstants.discountPercentByEnglishLevel[key] === 0) return null;
                  return (
                    <div key={key}>
                      <span className="name">{value[1]}</span>
                      <hr />
                      <span className="value plus">
                        +{currentConstants.discountPercentByEnglishLevel[key]}%
                      </span>
                    </div>
                  );
                })}
              </StyledSummaryTable>
              <StyledSummaryTable>
                <h3>
                  {isEn ? 'Long term contract discount' : 'Desconto para contrato a longo prazo'}
                </h3>
                {Object.entries(ContractLength).map((value) => {
                  const key = value[0] as keyof typeof ContractLength;
                  const discount = currentConstants.discountPercentByProjectLength[key] || 0;
                  if (discount === 0) return null;

                  return (
                    <div key={key}>
                      <span className="name">
                        {value[1]} {isEn ? 'months' : 'meses'}
                      </span>
                      <hr />
                      <span className="value minus">-{discount}%</span>
                    </div>
                  );
                })}
              </StyledSummaryTable>
            </SummaryCard>
          </Col>
          {(currentConstants.conditions || currentConstants.draft) && (
            <div>
              {currentConstants.conditions && (
                <>
                  <h2>{isEn ? 'Terms and Conditions' : 'Termos e Condições'}</h2>
                  {currentConstants.conditions.map((condition) => (
                    <StyledComment key={condition}>{condition}</StyledComment>
                  ))}
                </>
              )}
              {currentConstants.draft && (
                <>
                  <h2>{isEn ? 'Contract Draft' : 'Esboço do Contrato'}</h2>
                  {currentConstants.draft.map((draft) => (
                    <StyledComment key={draft}>{draft}</StyledComment>
                  ))}
                </>
              )}
            </div>
          )}
        </Row>
      )}
    </Container>
  );
};

// fetches firebase constants and only return TeamCalculator if they are loaded
const WrappedTeamCalculator: React.FC = () => {
  const router = useRouter();
  const hash = router.asPath.split('?')[1]?.split('hash=')[1];
  const [currentConstants, setCurrentConstants] = useState<Constants | null>(null);
  const [allConstantsLoading, setAllConstantsLoading] = useState(true);
  const [allConstants, setAllConstants] = useState<Constants[] | null>(null);
  const [isLogged, setIsLogged] = React.useState(false);

  const [currentBudget, setCurrentBudget] = useState<Budget | null>({
    id: hash,
  });
  const [budgetLoading, setBudgetLoading] = useState(true);
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState('');

  const unsubConstants = useRef<null | Unsubscribe>(null);
  const unsubAllConstants = useRef<null | Unsubscribe>(null);
  const unsubBudget = useRef<null | Unsubscribe>(null);

  const isEn = router.locale === 'en';

  // Hooks
  useEffect(() => {
    if (
      localStorage.getItem(`allowed-${hash}`) === 'true' ||
      localStorage.getItem('allowed-admin') === 'true'
    )
      setIsLogged(true);
  }, []);

  // ! Set Budget Listener
  // ! Set Constants Listener
  useEffect(() => {
    if (!unsubBudget.current) {
      setDocumentListener<Budget | null>(
        ['budgets'],
        hash,
        setCurrentBudget,
        unsubBudget,
        setBudgetLoading,
      );
    }

    if (!unsubAllConstants.current) {
      setListListener(['constants'], setAllConstants, unsubAllConstants, setAllConstantsLoading);
    }

    return () => {
      if (unsubConstants.current) {
        unsubConstants.current();
      }
      if (unsubBudget.current) {
        unsubBudget.current();
      }
      if (unsubAllConstants.current) {
        unsubAllConstants.current();
      }
    };
  }, []);

  useEffect(() => {
    if (!allConstants || allConstants?.length === 0) return;

    if (currentBudget?.constantsId) {
      setCurrentConstants(
        allConstants.find((constants) => constants.id === currentBudget.constantsId) as Constants,
      );
    } else {
      const lastConstants = allConstants.reduce((prev, curr) => {
        const prevDate = prev.startsAt?.toDate().getTime() || 0;
        const currDate = curr.startsAt?.toDate().getTime() || 0;
        if (currDate > prevDate) return curr;
        else return prev;
      }, allConstants[0]);
      setCurrentConstants(lastConstants);
      createOrUpdateItem(
        ['budgets'],
        {
          constantsId: lastConstants.id,
        },
        currentBudget?.id,
        true,
      );
    }
  }, [currentBudget?.constantsId, currentBudget?.id, allConstants]);

  // useEffect(() => {
  //   const handleEnter = (e: KeyboardEvent) => {
  //     if (e.key === 'Enter' && !isLogged) onSubmit();
  //   };

  //   window.addEventListener('keypress', handleEnter);

  //   return () => {
  //     window.removeEventListener('keypress', handleEnter);
  //   };
  // }, [isLogged, email]);

  // Methods
  const onSubmit = () => {
    setError('');
    const validEmail = !!currentBudget?.emailAddresses?.find(
      (emailAddress) => email === emailAddress,
    );

    if (validEmail) {
      localStorage.setItem(`allowed-${hash}`, 'true');
      setIsLogged(true);
    } else {
      setError(isEn ? 'Unauthorized email' : 'E-mail não autorizado');
      setEmail('');
    }
  };

  if (!hash) {
    return null;
  }

  if (allConstantsLoading || budgetLoading) {
    return (
      <Container centered={true}>
        <LoadingIcon />
      </Container>
    );
  }

  if (!currentBudget) {
    return (
      <Container centered={true}>
        <h1>{isEn ? 'Hash not found' : 'Hash não encontrado'}</h1>
      </Container>
    );
  }
  if (!currentConstants) {
    return (
      <Container centered={true}>
        <h1>{isEn ? 'Required constants are missing' : 'Constants obrigatórias estão faltando'}</h1>
      </Container>
    );
  }

  if (!isLogged)
    return (
      <LoginWrapper>
        {isEn ? (
          <>
            We must confirm that you have access to this budget simulation. <br /> Please enter your
            work email
          </>
        ) : (
          <>
            Precisamos confirmar que você tem acesso a esta simulação de orçamento. <br /> Por favor
            digite um email válido
          </>
        )}
        <form onSubmit={(e) => e.preventDefault()}>
          <Input
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <Button type="submit" onClick={() => onSubmit()}>
            {isEn ? 'Continue' : 'Entrar'}
          </Button>
        </form>
        {error ? <p style={{ color: 'red' }}>{error}</p> : null}
      </LoginWrapper>
    );

  return <TeamCalculator currentConstants={currentConstants} currentBudget={currentBudget} />;
};

export default function TeamCalculatorClient() {
  return (
    <ClientComponent>
      <WrappedTeamCalculator />
    </ClientComponent>
  );
}
