import styled, { css } from 'styled-components';
import media from '../../../styles/media';

export const Wrapper = styled.div<{
  textAlign: 'left' | 'top' | 'right' | 'bottom';
  background: 'primary' | 'transparent' | 'white';
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-bottom: 30px;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  background-color: ${(props) =>
    props.background === 'transparent' ? 'transparent' : props.theme.colors[props.background]};

  ${(props) =>
    props.textAlign === 'left' &&
    css`
      flex-direction: row;
    `}
  ${(props) =>
    props.textAlign === 'right' &&
    css`
      flex-direction: row-reverse;
    `}
  ${(props) =>
    props.textAlign === 'top' &&
    css`
      flex-direction: column-reverse;
    `}
  ${(props) =>
    props.textAlign === 'bottom' &&
    css`
      flex-direction: column;
    `}

  ${media('medium', true)} {
    flex-direction: column-reverse;
  }

  .textStyle {
    h1,
    h2,
    h3,
    h4 {
      ${(props) =>
        props.background === 'primary'
          ? css`
              color: white;
              background: white text;
            `
          : css`
              color: rgb(10, 182, 255);
              background: linear-gradient(to left, rgb(87, 221, 255) 0%, rgb(0, 180, 255) 100%) text;
            `}
      margin-top: 0px;
      font-weight: 500;
      line-height: 1.1;
      margin-bottom: 50px;
      font-size: 40px;
      ${media('extraSmallest', true)} {
        font-size: 25px;
      }
    }

    color: ${(props) => (props.background === 'primary' ? 'white' : props.theme.colors.darkText)};
    margin: 0px auto 28px;
    line-height: 2;
    font-size: 15px;
    font-weight: 400;
  }
`;
