// import { AvailableJobItemWrapper } from "./styles";
import { AvailableJobsWrapper, WithOutAvailableJobs } from './styles';
import AvailableJobItem, { AvailableJobItemProps } from '../../ui/AvailableJobItem';
import Typography from '../../ui/Typography';
import Button from '../../ui/Button';

type AvailableJobsProps = {
  title: string;
  description: string;
  jobs?: AvailableJobItemProps[];
};

const AvailableJobs: React.FC<AvailableJobsProps> = ({ title, description, jobs }) => {
  return (
    <AvailableJobsWrapper>
      <Typography variant="h1">{title}</Typography>
      {jobs && jobs.length > 0 ? (
        <>
          <Typography variant="p" color="darkText">
            {description}
          </Typography>
          <div className="jobs-style">
            {jobs &&
              jobs.map((job, index) => (
                <AvailableJobItem
                  key={index}
                  title={job.title}
                  isWorkRemote={job.isWorkRemote}
                  slug={job.slug}
                  baseSalary={job.baseSalary}
                />
              ))}
          </div>
        </>
      ) : (
        <WithOutAvailableJobs>
          <Typography variant="p" color="darkText">
            Estamos sem vagas abertas no momento. Te recomendamos se cadastrar no site{' '}
            <strong>Programadores em Juiz de Fora</strong> para receber avisos quando abrirmos as
            próximas oportunidades.
          </Typography>
          <a href="https://programador.emjuizdefora.com" target="_blank" rel="noreferrer">
            <Button>Acessar agora</Button>
          </a>
        </WithOutAvailableJobs>
      )}
    </AvailableJobsWrapper>
  );
};

export default AvailableJobs;
