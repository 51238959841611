import { DynamicZonesWrapper } from './styles';

/**
 * DynamicZoneTemplate
 */
const DynamicZoneTemplate: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <DynamicZonesWrapper>{children}</DynamicZonesWrapper>;
};

export default DynamicZoneTemplate;
