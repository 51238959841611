import { formatSrcSet } from '../../../utils/formatURLs';
import HTMLParsedContent from '../HTMLParsedContent';
import Typography from '../Typography';
import { DottedListWrapper } from './styles';

export type Topic = {
  id: string;
  title: string;
  body?: string | React.ReactNode;
};

export type DottedListProps = {
  id?: string;
  title?: string;
  background?: 'primary' | 'white' | 'transparent';
  isCard?: boolean;
  cardImage?: string;
  topics: Topic[];
};

/**
 * DottedList
 */
const DottedList: React.FC<DottedListProps> = ({
  title,
  topics,
  background,
  cardImage,
  isCard,
}) => {
  return (
    <DottedListWrapper
      isCard={isCard}
      background={background as 'primary' | 'white' | 'transparent'}
    >
      {isCard && (
        <div className="imageCard--container">
          <img
            srcSet={formatSrcSet(cardImage as string, '', {
              resize: { type: 'fit', width: '280', height: '170' },
              quality: 70,
            })}
            alt="Imagem de background"
            loading="lazy"
          />
        </div>
      )}
      {title && <Typography variant="h2">{title}</Typography>}
      <ul>
        {topics.map((topic) => (
          <li key={`dotted-list-li-${topic.id}`}>
            <Typography variant="h3" color="darkText">
              {topic.title}
            </Typography>
            <Typography variant="div" color="darkText">
              {typeof topic.body === 'string' ? (
                <HTMLParsedContent content={topic.body} />
              ) : (
                topic.body
              )}
            </Typography>
          </li>
        ))}
      </ul>
    </DottedListWrapper>
  );
};

export default DottedList;
