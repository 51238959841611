import { formatSrcSet } from '../../../utils/formatURLs';
import { Wrapper } from './styles';

export type WhereToFindType = {
  link: string;
  image: string;
};

export type WhereToFindProps = {
  whereToFind: WhereToFindType[];
};

const WhereToFind: React.FC<WhereToFindProps> = ({ whereToFind }) => {
  return (
    <Wrapper>
      {whereToFind.map((item, i) => {
        if (item.image && item.link) {
          return (
            <a
              key={i}
              href={item.link}
              target="__blank"
              rel="noopener noreferrer"
              title={'Store image (Google Play or App Store)'}
            >
              <img
                srcSet={formatSrcSet(item.image, 'whereToFind')}
                alt="Store image (Google Play or App Store)"
                loading="lazy"
              />
            </a>
          );
        } else return null;
      })}
    </Wrapper>
  );
};

export default WhereToFind;
