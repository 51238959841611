import { formatSrcSet } from '../../../utils/formatURLs';
import HTMLParsedContent from '../HTMLParsedContent';
import Typography from '../Typography';
import {
  TimelineContent,
  TimelineWrapper,
  TimelineContentItem,
  TimelineGallery,
  TimelineImage,
} from './styles';

export type TimelineContent = {
  id: string;
  title: string;
  body?: string | React.ReactNode;
};

export type TimelineProps = {
  title?: string;
  description?: string;
  footer?: string;
  gallery?: string[];
  disableSticky?: boolean;
  disableImageOverflow?: boolean;
  contents: TimelineContent[];
};

/**
 * Timeline
 */
const Timeline: React.FC<TimelineProps> = ({
  title,
  description,
  footer,
  contents,
  gallery,
  disableSticky,
  disableImageOverflow,
}) => {
  return (
    <TimelineWrapper $haveGallery={!!gallery}>
      {gallery && (
        <TimelineGallery $disableSticky={disableSticky}>
          <div>
            {gallery.map((image, i) => (
              <TimelineImage
                key={`timeline-image-${i}`}
                srcSet={formatSrcSet(image, '', {
                  resize: {
                    type: 'fit',
                    width: '500',
                    height: '500',
                  },
                  quality: 50,
                })}
                width="100%"
                index={i}
                alt=""
                aria-hidden
                $disableImageOverflow={disableImageOverflow}
              />
            ))}
          </div>
        </TimelineGallery>
      )}
      <TimelineContent>
        <Typography variant="h2" fontSize="2.75rem">
          {title}
        </Typography>
        <Typography variant="p" color="darkText">
          {description}
        </Typography>
        <div>
          {contents.map((content) => (
            <TimelineContentItem key={`timeline-item-${content.id}`}>
              <Typography variant="h3" fontSize="18px">
                {content.title}
              </Typography>
              <Typography variant="p" color="darkText">
                {content.body}
              </Typography>
            </TimelineContentItem>
          ))}
        </div>
        {footer && <HTMLParsedContent content={footer} />}
      </TimelineContent>
    </TimelineWrapper>
  );
};

export default Timeline;
