import styled, { css } from 'styled-components';
import { BaseContainer } from '../../../styles/Container';
import media from '../../../styles/media';
import { Enum_Componentcomponentsprojectslist_Background as Background } from '../../../types/schema';

export const AllProjectsContainer = styled(BaseContainer)`
  flex-direction: column;
  padding: 30px 0 80px 0;
  > h2 {
    color: ${(props) => props.theme.colors.darkText};
    margin: 48px 0 30px 0;
    :first-of-type {
      margin-bottom: 8px;
    }
  }
  > span {
    display: block;
    margin: 2px 0 24px 0;
    font-weight: 400;
  }
`;
const ContainerList = styled(BaseContainer).attrs({
  as: 'ul',
})``;

/* Lista de Projetos */
export const ProjectsList = styled(ContainerList)<{
  isDynamic?: boolean;
  background?: Background;
  projectsLength?: number;
}>`
  font-size: 0.8rem;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  flex-direction: row;
  /* Item da lista */
  li {
    border-radius: 20px;
    border: 1px solid ${(props) => props.theme.colors.cardBorder};
    overflow: hidden;
    position: relative;
    margin: 8px;
    /* Tamanhos pra grid */
    /* Menor que medium => 1 item */
    ${media('medium', true)} {
      width: 100%;
    }
    /* Medium - Large => 2 items */
    ${media('medium', 'large')} {
      width: calc(50% - 16px);
    }
    /* Large => 3 items */
    ${media('large')} {
      width: calc(${100 / 3}% - 16px);
    }
  }

  ${(props) => {
    const {
      isDynamic,
      background,
      projectsLength,
      theme: { colors },
    } = props;

    return isDynamic
      ? css`
          background-color: ${background === 'primary'
            ? colors.blue
            : background === 'white'
              ? colors.white
              : 'transparent'};
          gap: 42px;
          display: grid;
          grid-template-columns: 1fr;
          max-width: 1200px;
          margin: auto;
          justify-content: center;

          li {
            margin: 0;
            width: 100%;
          }

          ${media('small')} {
            grid-template-columns: repeat(2, 1fr);
          }

          ${media('mediumPlus')} {
            grid-template-columns: repeat(
              ${Number(projectsLength) >= 4 ? 4 : Number(projectsLength)},
              minmax(0px, 370px)
            );
          }

          ${background === 'primary' &&
          css`
            li {
              background-color: ${colors.white};
              h2,
              p {
                color: ${colors.darkText} !important;
              }
            }
          `}
        `
      : undefined;
  }}
`;
