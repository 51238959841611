import styled from 'styled-components';
import media from '../../../styles/media';

export const AvailableJobItemWrapper = styled.div`
  max-width: 1200px;
  padding: 16px;
  background-color: white;
  transition: all 200ms ease-in 0s;
  position: relative;
  margin-bottom: 32px;

  :hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: 0px 3px 20px 0px rgb(216 216 216 / 45%);
  }
  div.title-remote-style {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 10px;

    ${media('extraLarge', false)} {
      align-items: flex-start;
      flex-direction: column;
      text-align: left;
    }

    div:first-child {
      padding-right: 30px;
      a:hover {
        text-decoration: none;
      }
    }

    div:last-child {
      display: flex;
      align-items: flex-start;
      padding-right: 30px;
      p {
        margin: 0;
        font-size: 12px;
        cursor: default;
        border: none;
        background: #6fc995;
        background-color: rgb(111, 201, 149);
        padding: 3px 12px;
        display: inline-block;
        margin-bottom: 0;
        font-weight: inherit;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        white-space: nowrap;
        transition: all 0.3s;
        border-radius: 3px;
        text-transform: uppercase;
      }
    }
  }
  div:last-child {
    text-align: left;
  }
`;
