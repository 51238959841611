import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  a {
    margin: 8px;
    width: 180px;

    img {
      width: 100%;
    }
  }
  a:focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkText};
    outline-offset: 3px;
    border-radius: 3px;
  }
`;
