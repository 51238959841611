import Link from 'next/link';
import Typography from '../Typography';
import { BannerContainer, CallToAction } from './styles';

export type GetInTouchProps = {
  language?: string;
  title: string;
  description: string;
  button: string;
  background: 'primary' | 'white' | 'transparent';
};

const GetInTouch: React.FC<GetInTouchProps> = ({
  language,
  title,
  description,
  button,
  background,
}) => {
  return (
    <BannerContainer background={background}>
      <div>
        <h1>{title}</h1>
        {description && (
          <Typography variant="h2" fontSize="25px">
            {description}
          </Typography>
        )}
        <Link href={`/${language}/contact/`}>
          <CallToAction>{button}</CallToAction>
        </Link>
      </div>
    </BannerContainer>
  );
};

export default GetInTouch;
