// Check if an URL is local
export const isLocalUrl = (url: string) => {
  return !!url.match(/^(?:(?!http|www\.)).*/);
};

//Validate email
export const validateEmail = (email: string) => {
  if (!email || email === '') {
    return false;
  }
  const user = email.substring(0, email.indexOf('@'));
  const domain = email.substring(email.indexOf('@') + 1, email.length);
  if (
    user.length >= 1 &&
    domain.length >= 3 &&
    user.search('@') === -1 &&
    domain.search('@') === -1 &&
    user.search(' ') === -1 &&
    domain.search(' ') === -1 &&
    domain.search('.') !== -1 &&
    domain.indexOf('.') >= 1 &&
    domain.lastIndexOf('.') < domain.length - 1
  ) {
    return true;
  } else {
    return false;
  }
};

//Validate phone
export const validatePhone = (phone: string) => {
  if (phone === '' || phone === 'null') return false;
  const sanitizedPhone = phone.replace(/\D/g, '');
  return sanitizedPhone.length >= 10 && sanitizedPhone.length <= 11;
};

//Format phone
export const formatPhone = (text: string) => {
  let string = text;
  if (string.length < 16) {
    string = string.replace(/\D/g, '');
    string = string.replace(/^(\d{2})(\d)/g, '($1) $2');
    string = string.replace(/(\d)(\d{4})$/, '$1-$2');
  }
  return string.slice(0, 15);
};

// Transform new line to <br>
export const nl2br = (str?: string) => {
  if (typeof str === 'undefined' || str === null) return '';
  const breakTag = '<br>';
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
};

// Remove all string hashtags
export const removeHashtags = (str?: string) => {
  if (typeof str === 'undefined' || str === null) return '';
  return str.replace(/#/g, '');
};
