import styled from 'styled-components';

export const AvailableJobsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  div.jobs-style {
    border-radius: 3px;
    margin-bottom: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;
export const WithOutAvailableJobs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    margin-top: 20px;
  }
`;
