import styled from 'styled-components';

export const SectionWrapper = styled.div`
  max-width: 1200px;
  margin: 32px auto;
  text-align: center;
  p,
  h3 {
    margin: 0px auto 28px;
    max-width: 750px;
  }
`;

export const ButtonsContainer = styled.div<{
  direction?: string;
}>`
  display: flex;
  gap: 16px;
  margin-top: 64px;
  justify-content: center;
  ${(props) => props.direction === 'horizontal' && `display: flex`}
  ${(props) => props.direction === 'vertical' && `display: grid;`}

  a {
    text-decoration: none;
  }
`;
