import styled from 'styled-components';
import media from '../../../styles/media';

export const DevelopmentTimeContainer = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${media('medium')} {
      flex-direction: row;
      justify-content: space-between;
    }
    li {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      ${media('medium', true)} {
        + li {
          margin-top: 26px;
        }
      }
      h3 {
        font-size: 60px;
        margin: 0;
        font-weight: 400;
        color: ${(props) => props.theme.colors.darkBlue};
      }
    }
  }
`;
