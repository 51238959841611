import { useState } from 'react';
import ModalImage from '../../elements/ModalImage';
import HTMLParsedContent from '../HTMLParsedContent';
import Typography from '../Typography';
import { ServiceItemWrapper } from './styles';

export type ServiceItemProps = {
  id?: string;
  title: string;
  body: string;
  image: string;
  cloudinaryOriginalURL: string;
  inverted?: boolean;
};

/**
 * ServiceItem
 */
const ServiceItem: React.FC<ServiceItemProps> = ({
  title,
  body,
  image,
  cloudinaryOriginalURL,
  inverted,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  function handleIsOpen() {
    setIsOpen(!isOpen);
  }
  return (
    <ServiceItemWrapper $inverted={inverted}>
      {image && (
        <div className="image-container">
          <img
            role="button"
            className="img"
            srcSet={image}
            alt=""
            aria-hidden
            loading="lazy"
            onClick={() => {
              handleIsOpen();
            }}
          />
          <ModalImage image={cloudinaryOriginalURL} isOpen={isOpen} handleIsOpen={handleIsOpen} />
        </div>
      )}
      {/* <Gallery images={[image, image]} size="large" /> */}
      <div className="title-list">
        <Typography variant="h2" fontSize="45px">
          {title}
        </Typography>
        <HTMLParsedContent content={body} color="#646464" />
      </div>
    </ServiceItemWrapper>
  );
};

export default ServiceItem;
