import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { formatPhone } from '../../../utils/string';
import { CallToAction, Content, BannerContainer, ContentFooter, FormInput } from './styles';

export type FormType = {
  name?: string;
  email?: string;
  phone?: number;
};

export type LandingFormTemplateProps = {
  id: string;
  title: string;
  description: string;
  FormFields: {
    placeholder: string;
    type: 'text' | 'number';
  }[];
  button: string;
  formName: string;
  background: 'primary' | 'transparent' | 'white';
  onSubmit?: (data: FormType, e: React.FormEvent, title: string) => void;
};

const LandingFormTemplate: React.FC<LandingFormTemplateProps> = ({
  title,
  description,
  button,
  FormFields,
  onSubmit,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [phone, setPhone] = useState<string>();

  return (
    <form
      onSubmit={onSubmit && handleSubmit((data, e) => onSubmit(data, e as React.FormEvent, title))}
    >
      <Content backgroundColor={'#FAFAFA'}>
        <BannerContainer inverted full />
        <ContentFooter inverted>
          <div>
            <h1>{title}</h1>
            <h2>{description}</h2>
          </div>
          <div>
            {FormFields?.map((field) =>
              field.placeholder === 'mobilePhone' ? (
                <FormInput
                  inverted
                  key={field.placeholder}
                  {...register(field?.placeholder)}
                  required
                  placeholder={field?.placeholder}
                  onChange={(e) => setPhone(formatPhone(e.target.value))}
                  value={phone}
                />
              ) : (
                <FormInput
                  inverted
                  key={field.placeholder}
                  {...register(field?.placeholder)}
                  required
                  placeholder={field?.placeholder}
                />
              ),
            )}
          </div>
          <div>
            <label>{errors.message || ''}</label>
            <CallToAction white type="submit">
              {button}
            </CallToAction>
          </div>
        </ContentFooter>
      </Content>
    </form>
  );
};

export default LandingFormTemplate;
