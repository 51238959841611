import ServiceItem, { ServiceItemProps } from '../ServiceItem';
import { ServiceDisplayWrapper } from './styles';

export type ServiceDisplayProps = {
  invert?: boolean;
  services: ServiceItemProps[];
};

const ServiceDisplay: React.FC<ServiceDisplayProps> = ({ services, invert }) => {
  return (
    <ServiceDisplayWrapper>
      {services &&
        services.map((service, i) => (
          <ServiceItem key={service.id} inverted={invert && i % 2 === 0} {...service} />
        ))}
    </ServiceDisplayWrapper>
  );
};

export default ServiceDisplay;
