import styled from 'styled-components';
import media from '../../../styles/media';

export const TimelineWrapper = styled.div<{ $haveGallery: boolean }>`
  margin-top: 64px;
  display: grid;
  grid-template-columns: 1fr;
  ${media('mediumPlus')} {
    grid-template-columns: repeat(${(props) => (props.$haveGallery ? 2 : 1)}, 1fr);
  }
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

export const TimelineContent = styled.div`
  margin-top: 32px;
  ${media('large')} {
    margin-top: 0;
  }

  h1 {
    margin: 0px auto 28px;
  }
  p {
    margin: 0px auto 28px;
    color: ${(props) => props.theme.colors.darkText};
  }
`;

export const TimelineGallery = styled.div<{ $disableSticky?: boolean }>`
  margin: 0px 64px;
  > * {
    ${media('large')} {
      margin-bottom: 0;
      position: ${(props) => (props.$disableSticky ? 'block' : 'sticky')};
      top: 30%;
    }
  }
  > div {
    display: grid;
    gap: 16px;
    justify-content: center;

    ${media('large')} {
      justify-content: flex-end;
    }
  }
`;

export const TimelineImage = styled.img<{
  index: number;
  $disableImageOverflow?: boolean;
}>`
  max-width: 400px;
  width: 100%;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  transition: all 0.6s ease 0s;
  box-shadow: rgb(173, 173, 173) 0px 0px 55px 0px;
  position: relative;

  :hover {
    transform: scale(1.05) translateY(-5px);
  }

  ${media('mediumPlus')} {
    ${(props) =>
      props.$disableImageOverflow
        ? ''
        : `
      ${`top: -${props.index * 80}px`};
      left: ${props.index % 2 === 0 ? '' : '-100px'};
    `}
  }
`;

export const TimelineContentItem = styled.div`
  position: relative;
  padding: 0px 2rem 1.75rem 5.625rem;
  margin-right: 0px;
  box-sizing: border-box;

  ::before {
    top: 0.14rem;
    left: 2.4375rem;
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: rgb(255, 255, 255);
    border: 0.13375rem solid ${(props) => props.theme.colors.blue};
    transition: border-width 200ms ease-in 0s;
    border-radius: 100%;
    z-index: 1;
  }
  :hover::before {
    border: 0.43375rem solid ${(props) => props.theme.colors.blue};
  }
  h3 {
    margin-bottom: 0.5rem;
    margin-top: 0px;
  }
  p {
    margin-bottom: 0px;
    line-height: 1.8;
  }
  :not(:last-child)::after {
    top: 0.5rem;
    left: 2.875rem;
    width: 0.125rem;
    height: 100%;
    content: '';
    box-sizing: border-box;
    position: absolute;
    background-color: ${(props) => props.theme.colors.blue};
  }
`;
