import LandingFormTemplate, { FormType } from './template';
import { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { validatePhone } from '../../../utils/string';
import { submitHubSpotForm } from '../../../utils/hubspot';
import { useCallback } from 'react';
import { checkValidEmail } from '../../../utils/zeroBounce';
import logging from '../../../utils/logging';

export type LandingFormProps = {
  id: string;
  title: string;
  description: string;
  locale: string;
  FormFields: {
    placeholder: string;
    type: 'text' | 'number';
  }[];
  button: string;
  formName: string;
  background: 'primary' | 'transparent' | 'white';
  asPath: string;
};

const LandingForm: React.FC<LandingFormProps> = (props) => {
  const [_error, setFormError] = useState<string | undefined>(undefined);
  const [_formLoading, setLoading] = useState<boolean>(false);
  const [_currentSwipe, setSwipe] = useState<number[]>([0]);

  const siteUrl = process.env.NEXT_PUBLIC_SITE_URL || 'http://localhost:3000/';
  const methods = useForm<FormType>();

  const isEn = props.locale === 'en';

  const path = props.asPath;

  const submitForm = useCallback(
    async (data: FormType, e: React.FormEvent, title: string) => {
      e?.preventDefault();
      setFormError(undefined);
      if (data.name === '') {
        setFormError(isEn ? 'Invalid name' : 'Informe seu nome');
        return;
      }
      const { validEmail, response } = await checkValidEmail(data.email || '');
      if (!validEmail) {
        setFormError(isEn ? 'Invalid email' : 'Seu email não parece válido');
        logging.info('Invalid email on Landing Form', { email: data.email, response });
        return;
      }
      if (data.phone && !validatePhone(data.phone.toString())) {
        setFormError(isEn ? 'Invalid phone' : 'Seu telefone não parece válido');
        return;
      }
      setLoading(true);
      submitHubSpotForm(
        '75a12175-4a90-49bd-ba7f-44f85c762720',
        '',
        data as { [key: string]: string },
        {
          pageName: title,
          pageUri: siteUrl + path,
        },
      )
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.status === 'error') {
            setFormError(isEn ? 'An error has occurred' : 'Ocorreu um erro!');
          } else {
            setSwipe([1]);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log('SubmitError:', err);
          setFormError(isEn ? 'An error has occurred' : 'Ocorreu um erro!');
          setLoading(false);
        });
    },
    [isEn, path, siteUrl],
  );

  return (
    <FormProvider {...methods}>
      <LandingFormTemplate {...props} onSubmit={submitForm} />
    </FormProvider>
  );
};

export default LandingForm;
