import { Constants, weeksInMonth } from './consts';
import TeamItem from '../../../../types/TeamCalculator/TeamItem';
import { ContractLength, WorkloadType } from '../../../../types/TeamCalculator/Enums';
import { getHoursByWorkloadType } from '.';

export const calculateTeamItemCostPerMonth = (
  teamItem: TeamItem,
  constants: Constants,
  adjustmentRate?: number,
): number => {
  const { role, minimumExperience, englishLevel, workloadType, quantity } = teamItem;

  // Get base value
  const valueByHour = role ? constants.costsByHourByRole[role] : 0;
  const hoursPerWeek = getHoursByWorkloadType(
    WorkloadType.en[workloadType] as keyof typeof WorkloadType.en,
  );
  const valueByWeek = hoursPerWeek * valueByHour;

  // Get experience value increase
  const experiencePercent = minimumExperience
    ? constants.discountPercentByExperience[minimumExperience]
    : 0;

  // Get english value increase
  const englishPercent = englishLevel ? constants.discountPercentByEnglishLevel[englishLevel] : 0;

  // Get base value by month
  let workloadValue = 0;
  switch (workloadType) {
    case 'fullTime':
      workloadValue = valueByWeek * weeksInMonth;
      break;
    case 'partTime':
      workloadValue = valueByWeek * weeksInMonth;
      break;
    case 'onceWeekly':
      // approx 4 weeks per month?
      workloadValue = valueByWeek * weeksInMonth;
      break;
  }

  const totalValue =
    workloadValue +
    workloadValue * (experiencePercent / 100) +
    workloadValue * (englishPercent / 100);

  return totalValue * (quantity ?? 1) * (adjustmentRate ?? 1);
};

export const calculateTeamCost = (
  team: TeamItem[],
  constants: Constants,
  adjustmentRate?: number,
) => {
  const result = team.reduce((acc, item) => {
    let itemCost = 0;
    if (!item.free) itemCost = calculateTeamItemCostPerMonth(item, constants, adjustmentRate);

    return acc + itemCost;
  }, 0);

  // console.log('calculateTeamCost', result);

  return result;
};

export const getDiscountForProjectLength = (
  projectLength: keyof typeof ContractLength,
  constants: Constants,
): number => {
  const discountForProjectLength = constants.discountPercentByProjectLength[projectLength];
  return discountForProjectLength || 0;
};
