import styled, { css } from 'styled-components';
import { BaseContainer } from '../../../styles/Container';
import { Enum_Componentcomponentsprojectslist_Background as Background } from '../../../types/schema';

export const DynamicZonesWrapper = styled.div``;

export const DynamicButtonsContainer = styled.div<{
  direction?: string;
}>`
  gap: 16px;
  margin-top: 64px;
  justify-content: center;
  ${(props) => props.direction === 'horizontal' && `display: flex`}
  ${(props) => props.direction === 'vertical' && `display: grid;`}
`;

export const DynamicZoneContent = styled.div<{
  background?: string;
  paddingTop?: number;
  paddingBottom?: number;
  isBanner?: boolean;
  backgroundImage?: string | undefined | null;
  isFirstComponent?: boolean;
  isCustomPage?: boolean;
  backgroundColor?: string;
  textColor?: string;
}>`
  /* This validation for background was made for the old components */
  ${(props) =>
    props.background && props.background === 'primary'
      ? css`
          background-color: ${props.theme.colors.blue};
          color: white !important;
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          span,
          strong,
          a,
          i,
          b,
          p {
            color: white !important;
          }
        `
      : props.background &&
        css`
          background-color: ${props.background === 'white' ? 'white' : 'transparent'};
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          span,
          strong,
          a,
          i,
          b,
          p {
            color: ${(props) => props.theme.colors.darkText} !important;
          }
        `}

  /* New components must follow these backgroundColor validation */
  ${(props) =>
    props.backgroundColor &&
    css`
      background: ${props.backgroundColor};

      p,
      span,
      strong,
      a,
      i,
      b,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${props.textColor};
      }
    `}

  ${(props) =>
    props.backgroundImage &&
    css`
      background:
        url(${props.backgroundImage}) no-repeat,
        ${props.theme.colors.blue};
      background-position: center;
      background-size: cover;
    `};

  padding-right: ${(props) => (props.isBanner ? 0 : 26)}px;
  padding-left: ${(props) => (props.isBanner ? 0 : 26)}px;

  ${(props) =>
    props.isBanner &&
    css`
      padding-top: 0;
    `}

  padding-top: ${(props) =>
    props.paddingTop !== undefined
      ? props.paddingTop
      : props.isFirstComponent && !props.isBanner && props.isCustomPage
        ? 180
        : props.isFirstComponent && props.isBanner && props.isCustomPage
          ? 0
          : 64}px;

  padding-bottom: ${(props) =>
    props.paddingBottom !== undefined
      ? props.paddingBottom
      : props.isFirstComponent && !props.isBanner && props.isCustomPage
        ? 180
        : props.isFirstComponent && props.isBanner && props.isCustomPage
          ? 0
          : 64}px;
`;

export const TechWrapper = styled(BaseContainer)`
  padding-top: 32px;
  flex-direction: column;
  align-items: center;
  > div:nth-of-type(2) {
    padding-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  > div:nth-of-type(1) {
    p {
      font-weight: 400;
    }
  }
`;

export const StyledSection = styled.div<{ background?: Background }>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.background === 'primary'
      ? props.theme.colors.blue
      : props.background === 'white'
        ? props.theme.colors.white
        : 'transparent'};

  .text-box {
    text-align: center;
    padding: 0 1rem;
  }
`;
