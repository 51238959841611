import Typography from '../Typography';
import WhereToFind, { WhereToFindType } from '../WhereToFind';
import { DevelopmentTimeContainer } from './styles';

export type DevelopmentDetailsProps = {
  devCommits: number;
  devTime: number;
  devMonths: number;
  whereToFind?: WhereToFindType[];
  locale?: string;
};

const DevelopmentDetails: React.FC<DevelopmentDetailsProps> = ({
  devCommits,
  devTime,
  devMonths,
  whereToFind,
  locale,
}) => {
  const isEn = locale === 'en';
  return (
    <DevelopmentTimeContainer>
      <Typography variant="h2">
        {isEn ? 'Development Details' : 'Informações de desenvolvimento'}
      </Typography>
      <ul>
        {devTime && (
          <li>
            <h3>{devTime}</h3>
            <span>{isEn ? 'Hours of work' : 'Horas de trabalho'}</span>
          </li>
        )}
        {devMonths && (
          <li>
            <h3>{devMonths}</h3>
            <span>{isEn ? 'Months to deliver' : 'Meses até a entrega'}</span>
          </li>
        )}
        {devCommits && (
          <li>
            <h3>{devCommits}</h3>
            <span>{isEn ? 'Commits pushed' : 'Commits realizados'}</span>
          </li>
        )}
      </ul>
      {whereToFind && <WhereToFind whereToFind={whereToFind} />}
    </DevelopmentTimeContainer>
  );
};

export default DevelopmentDetails;
