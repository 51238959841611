import styled from 'styled-components';
import media from '../../../styles/media';

export const Wrapper = styled.div`
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  max-width: 500px;
  width: 100%;
  background: url('/contactCardBackground.svg');
  background-size: cover;
  margin: auto;

  & > svg {
    width: 250px;
    margin-top: 30px;
    filter: grayscale(1) brightness(0) invert(1);

    ${media('extraSmallest', true)} {
      width: 200px;
    }
  }

  ${media('extraSmall', true)} {
    padding: 16px;
  }

  @media screen and (max-width: 300px) {
    padding: 10px;
  }

  * {
    flex-shrink: 0;
  }
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  background-color: ${(props) => props.theme.colors.background};
  padding: 30px;
  padding-top: 80px !important;
  position: relative;
  margin-top: 84px;
  text-align: left;
  width: 100%;

  img.user-picture {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    padding: 6px;
    background-color: ${(props) => props.theme.colors.white};
    width: 170px;
    height: 170px;
    object-fit: cover;

    @media screen and (max-width: 300px) {
      width: 120px;
      height: 120px;
    }
  }

  div.buttons-container {
    display: flex;
    gap: 10px;
    margin-top: 20px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      font-weight: 500;
      border-radius: 12px;
      outline: none;
      border: none;
      padding: 10px 8px;
      height: 56px;
      flex: 1;
      transition: all 0.2s;
      cursor: pointer;
      font-size: 14px;
      text-decoration: none;

      svg {
        width: 20px;
      }

      :hover {
        filter: brightness(0.9);
      }
    }

    a:first-child {
      background: linear-gradient(to right, #1d42cf 0%, #3888fc 100%);
      color: white;
      border: 1px solid linear-gradient(to right, #1d42cf 0%, #3888fc 100%);
    }

    a:last-child {
      background-color: ${(props) => props.theme.colors.background};
      border: 1px solid #1f2f59;
    }

    ${media('small', true)} {
      flex-direction: column;

      a {
        padding: 14px;
        height: 60px;
      }
    }
  }

  /* div.share {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #87898e;
    border-bottom: 1px solid #87898e;
    height: 34px;
    width: max-content;
    margin: auto;
    margin-top: 20px;
    font-weight: 500;
  } */

  ${media('extraSmall', true)} {
    padding: 20px;
  }

  ${media('extraSmallest', true)} {
    padding: 14px;
    padding-top: 64px !important;

    img.user-picture {
      width: 140px;
      height: 140px;
    }
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  h2 {
    margin-bottom: 0px;
    color: #1f2f59;
  }

  span.user-role {
    font-size: 20px;
  }

  p.user-description {
    font-size: 15px;
    color: #585f73;
  }

  ${media('extraSmall', true)} {
    padding: 0px;
  }
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;

  & > a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    color: #1f2f59;
    text-decoration: none;

    div.icon-container {
      width: 40px;
      height: 40px;
      border-radius: 12px;
      border: 2px solid #c2dbff;
      background-color: #e5f0ff;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 22px;
      }
    }

    > div:last-child {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
      justify-content: center;
      flex: 1;
      overflow: hidden;

      & > span {
        text-decoration: underline;
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        display: block;
      }
    }
  }
`;

export const SocialMediasWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 22px;
  margin-top: 20px;
  flex-wrap: wrap;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 12px;
    background-color: #1f2f59;
    transition: all 0.2s;

    :hover {
      filter: brightness(0.9);
    }

    svg {
      color: white;
      font-size: 34px;
    }
  }

  ${media('extraSmallest', true)} {
    gap: 10px;
  }
`;

export const MessagesWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  flex: 1;
`;

export const MessageNow = styled.button`
  padding: 0 12px;
  height: 30px;
  width: max-content;
  border-radius: 16px;
  color: white !important;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #1d42cf 0%, #3888fc 100%);
  outline: none;
  border: none;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s;
  text-decoration: none !important;

  :hover {
    filter: brightness(0.9);
    text-decoration: none !important;
  }

  @media screen and (max-width: 340px) {
    span {
      display: none;
    }
  }
`;
