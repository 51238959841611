import styled from 'styled-components';

export const ProjectItemContainer = styled.li`
  :focus-within {
    outline: 2px solid ${(props) => props.theme.colors.darkText};
    outline-offset: 3px;
    border-radius: 3px;
  }
  .image {
    width: 100%;
    padding-top: 100%; /* 16:9 */
    overflow: hidden;
    position: relative;
  }
  .image img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ProjectItemDetails = styled.div`
  padding: 32px;
  padding-top: 36px;
  padding-bottom: 0;
  transition: transform 0.2s ease-in-out;
  h2 {
    margin: 0;
    color: ${(props) => props.theme.colors.darkText} !important;
  }

  > div {
    color: ${(props) => props.theme.colors.darkText};
    /* Parte com nome do cliente e localização */
    &:first-child {
      display: flex;
      > div {
        span:first-child {
          font-weight: 500;
        }
        span.info {
          color: ${(props) => props.theme.colors.darkTextPale};
          font-weight: 400;
        }
        /* outline: 2px solid blue; */
        display: inline-flex;
        &:first-child {
          flex: 1;
          text-align: left;
          color: ${(props) => props.theme.colors.darkText};
          flex-direction: column;
          align-items: flex-start;
          padding-bottom: 6px;
          span {
            &:first-child {
              color: ${(props) => props.theme.colors.darkText};
              font-weight: 500;
            }
            font-weight: 400;
          }
        }
        &:nth-child(2) {
          align-items: flex-start;
          margin-top: 0px;
          svg {
            height: 1.35em;
            width: 1.35em;
            padding: 0.1em;
            margin-right: 1px;
          }
          flex: 1;
          text-align: right;
          flex-direction: column;
          align-items: flex-end;
          font-size: 1em;
          font-weight: 400;
          margin-bottom: 10px;
          span {
            display: flex;
            align-items: center;
          }
        }
      }
    }
    &:nth-child(2) {
      p {
        font-weight: 400;
      }
      div.description {
        margin-bottom: 16px;
        p {
          height: unset;
          margin: 0;
          font-size: 1rem;
        }
      }
    }
    > div:nth-of-type(2) {
      display: flex;
      flex-wrap: wrap;
      margin: -3px;
      a {
        padding: 4px 6px;
        border: 1px solid ${(props) => props.theme.colors.blue};
        border-radius: 3px;
        color: ${(props) => props.theme.colors.blue};
        margin: 3px;
        transition: all 0.1s ease-in-out;
        :hover {
          color: ${(props) => props.theme.colors.white};
          background-color: ${(props) => props.theme.colors.blue};
        }
      }
    }
  }
`;

export const ProjectItemDescriptionWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;
