import Button from '../Button';
import {
  ComponentComponentsButton,
  ComponentComponentsCardDisplay,
  ComponentComponentsService,
} from '../../../types/schema';
import { ButtonVariant } from '../Button';
import CardsDisplay, { Card, CardsDisplayProps } from '../CardsDisplay';
import Gallery from '../Gallery';
import ServiceDisplay from '../ServiceDisplay';
import { ServiceItemProps } from '../ServiceItem';
import Typography from '../Typography';
import { ButtonsContainer, SectionWrapper } from './styles';
import Link from 'next/link';

export type ButtonDirection = 'horizontal' | 'vertical';

export type SectionProps = {
  title: string;
  titleBold?: boolean;
  subtitle?: string;
  buttons?: ComponentComponentsButton[];
  gallery?: string[];
  cloudinaryOriginalURLs?: string[];
  galleryImagePosition?: 'covered' | 'contained';
  cardDisplay?: ComponentComponentsCardDisplay | undefined;
  cards?: Card[];
  service?: ComponentComponentsService | undefined;
  serviceItens?: ServiceItemProps[];
  buttonDirection?: ButtonDirection;
  isJustSubtitle?: boolean;
};

const Section: React.FC<SectionProps> = ({
  title,
  titleBold,
  subtitle,
  // children,
  cardDisplay,
  gallery,
  cloudinaryOriginalURLs,
  galleryImagePosition,
  buttons,
  cards,
  serviceItens,
  service,
  buttonDirection,
  isJustSubtitle,
}) => {
  return (
    <SectionWrapper>
      {title && (
        <Typography variant="h2" fontSize="2.75rem" fontWeight={titleBold ? '800' : '500'}>
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography variant={isJustSubtitle ? 'h3' : 'p'} color="darkText">
          {subtitle}
        </Typography>
      )}
      {/* Render card */}
      {!!(cardDisplay && cardDisplay.cards) && (
        <CardsDisplay {...(cardDisplay as unknown as CardsDisplayProps)} cards={cards as Card[]} />
      )}
      {/* Render gallery */}
      {gallery && gallery.length > 0 && (
        <Gallery
          columns={gallery.length === 1 ? 1 : undefined}
          size={gallery.length === 1 ? 'medium' : undefined}
          images={gallery as string[]}
          cloudinaryOriginalURLs={cloudinaryOriginalURLs as string[]}
          galleryImagePosition={galleryImagePosition}
        />
      )}
      {/* Render service */}
      {service && (
        <ServiceDisplay invert={!!service.invert} services={serviceItens as ServiceItemProps[]} />
      )}
      {/* Render buttons */}
      {buttons && buttons.length > 0 && (
        <ButtonsContainer direction={buttonDirection ? buttonDirection : ''}>
          {buttons.map((button) =>
            button.action ? (
              <Link key={button.title} passHref href={button.action}>
                <Button key={button?.id} variant={(button?.type || 'default') as ButtonVariant}>
                  {button?.title}
                </Button>
              </Link>
            ) : (
              <Button key={button?.id} variant={(button?.type || 'default') as ButtonVariant}>
                {button?.title}
              </Button>
            ),
          )}
        </ButtonsContainer>
      )}
    </SectionWrapper>
  );
};

export default Section;
