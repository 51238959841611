import React from 'react';
import { ComponentComponentsDottedList } from '../../../types/schema';
import DottedList, { Topic } from '../DottedList';
import { Wrapper } from './styles';

export type TextWithSideListProps = {
  body: string;
  textAlign: TextAlign;
  itemsCard: ComponentComponentsDottedList;
  background: 'primary' | 'transparent' | 'white';
};

export type TextAlign = 'left' | 'top' | 'right' | 'bottom';

const TextWithSideList: React.FC<TextWithSideListProps> = ({
  body,
  textAlign,
  itemsCard,
  background,
}) => {
  const isCard = itemsCard?.type === 'card';
  return (
    <Wrapper background={background} textAlign={textAlign as TextAlign}>
      <div className="textStyle" dangerouslySetInnerHTML={{ __html: body }} />
      {itemsCard && itemsCard?.topics && (
        <DottedList
          title={itemsCard?.title as string}
          topics={itemsCard?.topics as Topic[]}
          isCard={isCard}
          cardImage={itemsCard?.image?.data?.attributes?.url}
          background="transparent"
        />
      )}
      {/* <HTMLParsedContent className="textStyle" content={body!} /> */}
    </Wrapper>
  );
};

export default TextWithSideList;
