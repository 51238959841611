/* eslint-disable camelcase */
import smartTrim from './wordsRemove';

export const SEO = (SEOProps: {
  pathname: string;
  locale: string;
  title?: string;
  defaultTitle?: {
    enTitle?: string;
    ptTitle?: string;
  };
  hideAppMastersTitle?: boolean;
  description?: string;
  image?: string;
}) => {
  const siteUrl = process.env.NEXT_PUBLIC_SITE_URL || 'http://localhost:3000/';
  const url = siteUrl + SEOProps.locale + SEOProps.pathname;

  const isEn = SEOProps.locale === 'en';

  const metaTitle =
    SEOProps.title || SEOProps.defaultTitle
      ? SEOProps.title
        ? `${SEOProps.title}${
            !!SEOProps.hideAppMastersTitle
              ? ''
              : ` - App Masters ${
                  isEn
                    ? '- Web and Mobile Software Development - Brazil'
                    : '- Desenvolvimento Web e Mobile - Juiz de Fora/MG'
                }`
          }`
        : isEn
          ? `${SEOProps.defaultTitle?.enTitle}${
              !!SEOProps.hideAppMastersTitle
                ? ''
                : ' - App Masters - Web and Mobile Software Development - Brazil'
            }`
          : `${SEOProps.defaultTitle?.ptTitle}${
              !!SEOProps.hideAppMastersTitle
                ? ''
                : ' - App Masters - Desenvolvimento Web e Mobile - Juiz de Fora/MG'
            }`
      : null;

  return {
    ...(metaTitle && { title: metaTitle }),
    ...(SEOProps.description && { description: smartTrim(SEOProps.description, 165, ' ', '...') }),
    canonical: url,
    openGraph: {
      type: 'website',
      locale: SEOProps.locale,
      url: url,
      site_name: 'App Masters',
      images: [
        {
          url: SEOProps.image || `${process.env.NEXT_PUBLIC_SITE_URL}appmasters-metaimage.png`,
          width: 800,
          height: 600,
        },
      ],
    },
  };
};

export const additionalLinkTags = (paths: [path1: string, path2: string]) => {
  const siteUrl = process.env.NEXT_PUBLIC_SITE_URL || 'http://localhost:3000/';
  return paths.map((path, i) => {
    const locale = i === 0 ? 'pt' : 'en';
    return {
      rel: 'alternate',
      href: siteUrl + locale + '/' + path,
      hrefLang: locale,
    };
  });
};
