import styled, { css } from 'styled-components';
import media from '../../../styles/media';

export const BannerContainer = styled.section<{
  background: 'primary' | 'transparent' | 'white';
}>`
  width: 100%;
  position: relative;
  height: 100%;
  padding: 0 22px;
  overflow: hidden;
  background: ${(props) =>
    props.background === 'transparent' ? 'transparent' : props.theme.colors[props.background]};
  > div {
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    text-align: center;
    height: 100%;
    color: props.theme.colors.darkText;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    :nth-of-type(2) {
      position: absolute;
    }
  }
  h1 {
    color: ${(props) => (props.background === 'primary' ? 'white' : 'black')};
    font-size: 25px;
    line-height: 1.2em;
    ${media('medium', 'large')} {
      font-size: 40px;
    }
    ${media('large')} {
      font-size: 50px;
    }
  }
  ${media('extraSmallest', 'medium')} {
    padding: 0;
  }
  h3 {
    color: ${(props) => (props.background === 'primary' ? 'white' : 'black')};
    font-size: 22px;
    line-height: 1.2em;
    ${media('medium', 'large')} {
      font-size: 22px;
    }
    ${media('extraSmallest', 'medium')} {
      font-size: 15px;
    }
  }
  ${media('extraSmallest', 'medium')} {
    height: fit-content;
  }

  button {
    ${(props) =>
      props.background !== 'primary'
        ? css`
            background-color: ${props.theme.colors.blue};
            color: white;
          `
        : css`
            background-color: white;
            color: ${props.theme.colors.blue};
          `};
  }
`;

export const CallToAction = styled.button`
  margin: 30px auto;
  display: block;
  box-sizing: border-box;
  outline: 0;
  width: fit-content;
  padding: 20px 20px;
  font-size: 1em;
  text-transform: uppercase;
  ${media('medium')} {
    padding: 20px 40px;
    font-size: 1.2em;
  }
  font-weight: 400;
  border-radius: 6px;
  cursor: pointer;

  border: none;
  text-decoration: none;
`;
