const smartTrim = (str: string, length: number, delim: string, appendix: string) => {
  if (!str) return '';
  if (str.length <= length) return str.replace(/<[^>]*>?/gm, '');

  str = str.replace(/<[^>]*>?/gm, '');
  let trimmedStr = str.slice(0, length + delim.length);

  const lastDelimIndex = trimmedStr.lastIndexOf(delim);
  if (lastDelimIndex >= 0) trimmedStr = trimmedStr.slice(0, lastDelimIndex);

  if (trimmedStr) trimmedStr += appendix;
  return trimmedStr;
};

export default smartTrim;
