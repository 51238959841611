import styled from 'styled-components';
import { Container } from '../../../styles/Container';
import media from '../../../styles/media';

export const CallToAction = styled.button<{ white?: boolean }>`
  margin-top: 23px;
  display: block;
  box-sizing: border-box;
  outline: 0;
  width: fit-content;
  padding: 10px 20px;
  font-size: 1em;
  ${media('medium')} {
    padding: 14px 40px;
    font-size: 1.2em;
  }
  font-weight: 400;
  color: ${(props) => (props.white ? '#3888FC' : 'white')} !important;
  border-radius: 6px;
  cursor: pointer;
  background: ${(props) =>
    props.white ? 'white' : 'linear-gradient(to left, #57ddff 0%, #3888FC 100%)'};
  border: none;
  text-decoration: none;
  :hover {
    text-decoration: none;
    background: ${(props) => (props.white ? 'white' : '#57ddff')};
  }
`;

export const FormInput = styled.input<{ inverted?: boolean }>`
  height: 45px;
  width: 100%;
  color: ${(props) => props.theme.colors[props.inverted ? 'white' : 'darkGrey']};
  font-size: 18px;
  font-weight: lighter;
  align-self: center;
  padding-left: 10px;
  padding-right: 10px;
  border: ${(props) => `2px solid ${props.theme.colors[props.inverted ? 'white' : 'darkGrey']}`};
  background-color: transparent;
  border-radius: 5px;
  margin: 20px;
  &::placeholder {
    color: ${(props) => props.theme.colors[props.inverted ? 'white' : 'darkGrey']} !important;
    opacity: 1;
  }
`;

export const Content = styled.div<{
  backgroundColor?: string;
  height?: number;
}>`
  position: relative;
  overflow: hidden;
  display: flex;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.white};
  p {
    font-weight: 400;
  }
  min-height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
`;

export const ContentFooter = styled(Container)<{ inverted?: boolean }>`
  /* Container */
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  color: ${(props) => props.theme.colors[props.inverted ? 'white' : 'darkGrey']};
  text-align: center;
  width: 80% !important;
  padding-top: 96px;
  padding-bottom: 96px;
  > div:nth-child(1) {
    width: 100%;
    h1 {
      padding-top: 46px;
      font-size: 40px;
      line-height: 1.2em;
      font-weight: lighter;
      margin-bottom: 0px;
    }
    p,
    h2 {
      font-size: 20px;
      font-weight: normal;
    }
  }
  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    ${media('large')} {
      flex-direction: row;
    }
  }
  > div:nth-child(3) {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    ${media('large')} {
      margin-right: 20px;
    }
  }
`;

export const BannerContainer = styled.div<{
  inverted?: boolean;
  full?: boolean;
}>`
  width: 100%;
  height: ${(props) => (props.full ? '100%' : '90%')};
  ${media('medium')} {
    height: ${(props) => (props.full ? '100%' : '90%')};
  }
  position: absolute;
  background: linear-gradient(45deg, rgba(8, 184, 255, 1) 0%, rgba(86, 220, 255, 1) 100%);
  clip-path: ${(props) =>
    props.inverted
      ? 'polygon(0 0, 100% 9%, 100% 100%, 0 91%)'
      : 'polygon(0 0, 100% 0, 100% 100%, 0 91%)'};
`;
