import styled from 'styled-components';

export const GlassDoorWrapper = styled.div`
  display: flex;

  align-content: center;
  justify-content: center;
`;

export const BannerGlassDoor = styled.div`
  max-width: 1200px;
  padding: 2.5rem 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;

  background-color: transparent;

  div:nth-child(2) {
    margin-bottom: 30px;
  }
  a:focus-within {
    outline: 2px solid ${(props) => props.theme.colors.darkText};
    outline-offset: 3px;
    border-radius: 3px;
  }

  img {
    margin-top: 35px;
    margin-bottom: 30px;
    width: 100%;
    max-width: 582px;
  }
`;
