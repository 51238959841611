import { FaTimesCircle } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import media from '../../../styles/media';
import { AppTheme } from '../../../styles/theme';

export const Container = styled.div<{ centered?: boolean }>`
  max-width: 1200px;
  margin: 0 auto;
  text-align: ${(props) => (props.centered ? 'center' : 'left')};

  form {
    display: flex;
    flex-direction: column;
  }

  & > header {
    display: flex;
    justify-content: space-between;

    h1 {
      margin-bottom: 0;
    }

    div {
      display: flex;
      gap: 10px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;

        span {
          font-size: 30px;
          font-weight: bold;
          margin-bottom: 4px;
        }
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .button-row {
    @media screen and (max-width: 850px) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
  }

  .accepted-row {
    h2 {
      margin-bottom: unset;
      display: flex;
      gap: 6px;
      align-items: center;
      svg {
        margin: none;
      }
    }
  }

  .summary-row {
    align-items: start;
    justify-content: start;
    gap: 15px;
    margin-top: 20px;
    @media screen and (max-width: 850px) {
      justify-content: center;
      gap: 20px;
    }
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MemberCard = styled.div<{ locked?: boolean }>`
  position: relative;

  .ribbon {
    background-color: ${(props) => props.theme.colors.blue};
    color: ${(props) => props.theme.colors.white};
    border-radius: 4px 4px 4px 0;
    font-size: 0.8rem;
    padding: 4px 12px;
    left: -10px;
    top: 12px;
    z-index: 1;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      background-color: ${(props) => props.theme.colors.darkBlue};
      bottom: -10px;
      left: 0;
      border-radius: 0 0 0 100%;
    }
  }

  &.free {
    margin-top: -20px;
  }

  ${({ locked }) =>
    locked &&
    css`
      select,
      input {
        opacity: 1;
        appearance: none;
        background-color: transparent;
        border: none;
        padding: 0;
      }

      .member-cost.free {
        p:first-child {
          text-decoration: line-through;
        }
      }
    `}

  &:hover .hover-button {
    display: block;
  }
  .hover-button {
    display: none;
  }

  .form-body {
    /* grid-template-columns: repeat(8, 1fr); */
    @media screen and (min-width: 850px) {
      display: grid;
      grid-template-columns:
        100px 55px minmax(100px, 227px) minmax(100px, 156px) minmax(100px, 1fr) minmax(100px, 163px)
        minmax(min-content, 1fr);
      grid-gap: 20px;
      align-items: center;
      justify-content: space-between;
    }

    @media screen and (max-width: 850px) {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      gap: 20px;
    }

    /* display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; */

    position: relative;
    width: 100%;
    height: auto;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0px 3px 20px 0px rgba(216, 216, 216, 0.45);
    padding: 15px;
    margin-bottom: 20px;

    .member-image {
      /* overriding next-image 100% defaults */
      max-height: 100px !important;
      max-width: 100px !important;
      min-height: 100px !important;
      min-width: 100px !important;
      height: 100px !important;
      width: 100px !important;
      align-self: center;
    }

    .member-cost {
      background-color: ${(props) => props.theme.colors.lighterGreen};
      min-height: 130px;
      min-width: 100px;
      color: ${(props) => props.theme.colors.green};
      margin: -15px -16px -15px 0px;
      /* justify-self: end; */
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 1.6rem;
      font-weight: 600;

      p {
        display: flex;
        align-items: baseline;
        margin: 0;

        span {
          font-size: 0.9rem;
          letter-spacing: 0;
        }
      }

      p:not(:first-child) {
        font-size: 0.9rem;
      }
    }
  }

  .close-button {
    background-color: ${(props) => props.theme.colors.red};
    border-radius: 50%;
  }
`;

export const StyledButton = styled(FaTimesCircle)`
  color: ${(props) => props.theme.colors.lightRed};
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 2.5rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledLabel = styled.label`
  color: ${(props) => props.theme.colors.darkAuthorText};
  font-size: 0.8rem;
`;

export const StyledComment = styled.div`
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 1rem;
  color: ${(props) => props.theme.colors.grey};
  vertical-align: middle;
  margin-bottom: 10px;
  font-size: 1rem;
  opacity: 0.8;
  @media screen and (max-width: 850px) {
    align-items: center;
    text-align: center;
  }

  svg {
    height: 0.8rem;
    width: 0.8rem;
  }
`;

export type ButtonVariant = 'default' | 'secondary';

type SummaryCardProps = {
  variant?: ButtonVariant | 'primary';
  fixedWidth?: boolean | number;
} & { theme: AppTheme };

export const SummaryCard = styled.div<SummaryCardProps>`
  ${({ theme: { colors }, variant }) => {
    const variants = {
      ['primary']: css`
        background-color: ${colors.tertiaryBlue};
        color: ${colors.tertiaryBlueText};
      `,
      ['secondary']: css`
        background-color: ${colors.white};
        color: ${colors.authorText};
      `,
      ['default']: css`
        background-color: ${colors.lightBlue};
        color: ${colors.white};
      `,
    };

    return variants[variant || 'default'];
  }}

  border-radius: 25px;
  padding: 25px;
  height: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: ${(props) =>
    typeof props.fixedWidth !== undefined
      ? typeof props.fixedWidth === 'number'
        ? `${props.fixedWidth}px`
        : '320px'
      : css`minmax(min-content, 350px)`};
  h4,
  h3 {
    font-weight: bold;
    margin: 0;
    white-space: nowrap;
  }

  hr {
    border-color: ${(props) => props.theme.colors.tertiaryBlueText}44;
  }

  .value-container {
    margin-bottom: 16px;
    label,
    p {
      font-size: 14px;
      margin: 0 0 8px 0;
      font-weight: 600;
      color: ${(props) => props.theme.colors.tertiaryBlueText}aa;

      &.contract,
      &.hour {
        color: ${(props) => props.theme.colors.darkGreen};
        font-size: 1.2rem;
        opacity: 0.8;
        font-weight: normal;
      }

      &.month {
        color: ${(props) => props.theme.colors.darkGreen};
        font-size: 2.5rem;
        font-weight: bold;

        span {
          color: ${(props) => props.theme.colors.darkGreen};
          font-size: 1.5rem;
        }
      }

      span {
        font-weight: bold;
        font-size: 1.5rem;
        color: ${(props) => props.theme.colors.tertiaryBlueText};

        .text {
          font-size: 1.2rem;
        }
      }

      .hours-month {
        font-weight: normal;
        font-size: 1.4rem;

        .text {
          font-weight: normal;
        }
      }
    }

    .select-wrapper {
      margin-top: 8px;
      display: flex;
      align-items: center;

      select {
        font-weight: 600;
        width: 150px;
        margin-right: 15px;

        &:disabled {
          opacity: 1;
          appearance: none;
          background-color: transparent;
          border: none;
          padding: 0;
          color: ${(props) => props.theme.colors.tertiaryBlueText};
          font-size: 1.4rem;
          width: auto;
        }
      }
    }

    .discount {
      font-size: 1.4rem;
      font-weight: normal;

      span {
        font-weight: bold;
      }
    }

    .discount-info {
      color: ${(props) => props.theme.colors.tertiaryBlueText};
      font-size: 1rem;
    }
  }
`;

export const StyledSummaryTable = styled.div`
  :not(:last-child) {
    margin-bottom: 1rem;
  }

  h3 {
    margin: 0;
    color: ${(props) => props.theme.colors.darkText};
  }
  div {
    display: flex;
    justify-content: space-between;
    color: ${(props) => props.theme.colors.darkText};
    gap: 10px;
    margin: 8px 0;
    font-size: 1rem;

    .name {
      min-width: max-content;
    }

    hr {
      width: 100%;
      border: none;
      height: 2px;
      background-image: linear-gradient(to right, #0004 50%, rgba(255, 255, 255, 0) 0%);
      background-position: left;
      background-size: 14px 2px;
      background-repeat: repeat-x;
      margin: auto;
    }

    .value {
      flex-shrink: 0;
      border-radius: 12px;
      padding: 2px 8px;
      font-weight: 600;
    }

    .plus {
      background-color: ${(props) => props.theme.colors.darkBlue}33;
      color: ${(props) => props.theme.colors.darkBlue};
    }

    .minus {
      background-color: ${(props) => props.theme.colors.green}33;
      color: ${(props) => props.theme.colors.green};
    }
  }
`;

export const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 200px;
  margin-left: auto;
`;

export const LoginWrapper = styled.div`
  max-width: 550px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  text-align: center;

  form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    input {
      flex: 1;
    }
  }

  ${media('mediumPlus', true)} {
    padding: 20px;
  }

  ${media('extraSmall', true)} {
    padding: 0px;

    form {
      flex-direction: column;
      align-items: center;
    }
  }
`;
