import styled, { css } from 'styled-components';
import media from '../../../styles/media';

export const DottedListWrapper = styled.div<{
  background: 'primary' | 'white' | 'transparent';
  isCard: boolean | undefined;
}>`
  padding-left: 26px;
  background-color: ${(props) => props.isCard && props.theme.colors.gray};
  max-width: 360px;
  min-width: 360px;

  ${media('extraSmallest', true)} {
    max-width: 100%;
    min-width: 100%;
    padding: 10px 6px;
  }

  ${(props) =>
    props.isCard
      ? css`
          padding: 16px 32px;
          width: max-content;
          border-radius: 4px;
          transition: all 200ms ease-in 0s;
          &:hover {
            box-shadow: 0px 3px 20px 0px rgba(216, 216, 216, 1);
          }
        `
      : css`
          max-width: 1240px;
          padding-left: -100px;
          margin-right: auto;
          margin-left: auto;
          transform: translateX(-20px);
        `};
  img {
    object-fit: cover;
  }
  div.imageCard--container {
    width: 300px;
    height: 170px;
    display: flex;
    justify-content: center;
  }

  h1 {
    margin-top: 0px;
    ${(props) =>
      props.isCard
        ? css`
            font-size: 16px;
            margin-bottom: 0px;
            color: props.theme.colors.darkText !important;
          `
        : css`
            font-size: 45px;
            margin-bottom: 50px;
          `};
    font-weight: 500;
    line-height: 1.1;
  }

  ul {
    list-style: outside none none;
    padding-left: 0;
    li {
      position: relative;
      padding-left: 22px;
      line-height: 1.82em !important;
      max-width: 100%;
      min-width: 100%;
      h3 {
        ${(props) =>
          props.isCard &&
          css`
            color: ${(props) => props.theme.colors.darkText} !important;
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 400;
          `}
      }
      p {
        margin-bottom: ${(props) => (props.isCard ? '25px' : '40px')};
        color: ${(props) => props.theme.colors.darkText};
      }
      &:hover {
        ::before {
          border-width: 6px;
        }
      }
      ::before {
        transition: border-width 200ms ease-in 0s;
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        border-width: 3px;
        border-style: solid;
        top: 7px;
        left: 0px;
        border-radius: 50%;
        border-color: rgb(10, 182, 255);
        background: rgb(255, 255, 255) none repeat scroll 0% 0%;
        p,
        span {
          color: ${(props) => props.theme.colors.darkText} !important;
        }
      }
    }
  }
`;
