import React from 'react';
import {
  AiFillGithub,
  AiFillLinkedin,
  AiOutlineInstagram,
  AiOutlineMail,
  AiOutlinePhone,
} from 'react-icons/ai';
import { BiMessageDetail } from 'react-icons/bi';
import { BsLink45Deg } from 'react-icons/bs';
import {
  CardInfo,
  ContactWrapper,
  MessageNow,
  MessagesWrapper,
  SocialMediasWrapper,
  UserInfo,
  Wrapper,
} from './styles';
import DownloadIcon from '../../../styles/svgs/download.svg';
import { FaCalendarAlt, FaWhatsapp } from 'react-icons/fa';

const cardData = {
  name: 'Tiago Gouvea',
  picture: '/tiago.jpeg',
  role: 'CEO',
  description:
    'Entrepreneur and Senior Developer with more than 22+ years of experience developing systems for all sorts of industries. Team manager and project coordinator at App Masters, working with companies around the world turning ideas into software applications. Google Community Expert, community builder and mentor for local startups.',
  contacts: [
    {
      text: '+55 32 8873-5683',
      icon: <AiOutlinePhone style={{ transform: 'rotateY(180deg)' }} />,
      href: 'tel:553288735683',
      extra: (
        <MessagesWrapper>
          <MessageNow onClick={() => window.open('https://wa.me/553288735683', '_blank')}>
            <FaWhatsapp size={16} />
            <span>Whatsapp</span>
          </MessageNow>
          <MessageNow onClick={() => window.open('sms:553288735683', '_blank')}>
            <BiMessageDetail size={16} />
            <span>SMS</span>
          </MessageNow>
        </MessagesWrapper>
      ),
    },
    {
      text: 'tiago@appmasters.io',
      icon: <AiOutlineMail />,
      href: 'mailto:tiago@appmasters.io',
    },
    {
      text: 'appmasters.io',
      icon: <BsLink45Deg />,
      href: 'https://appmasters.io',
    },
  ],
  socialMedias: [
    {
      url: 'https://github.com/TiagoGouvea',
      icon: <AiFillGithub />,
    },
    {
      url: 'https://www.linkedin.com/in/tiagogouvea/',
      icon: <AiFillLinkedin />,
    },
    {
      url: 'https://www.instagram.com/tiagogouvea/',
      icon: <AiOutlineInstagram />,
    },
  ],
};

const ContactCard: React.FC = () => {
  return (
    <Wrapper>
      <CardInfo>
        <img className="user-picture" src={cardData.picture} alt="User picture" />
        <UserInfo>
          <h2>{cardData.name}</h2>
          <span className="user-role">{cardData.role}</span>
          <p className="user-description">{cardData.description}</p>
          <ContactWrapper>
            {cardData.contacts.map((contact) => (
              <a key={contact.text} rel="noreferrer" href={contact.href} target="_blank">
                <div className="icon-container">{contact.icon}</div>
                <div>
                  <span>{contact.text}</span>
                  {contact.extra}
                </div>
              </a>
            ))}
          </ContactWrapper>
          <SocialMediasWrapper>
            {cardData.socialMedias.map((media) => (
              <a key={media.url} href={media.url} target="_blank" rel="noreferrer">
                {media.icon}
              </a>
            ))}
          </SocialMediasWrapper>
        </UserInfo>
        <div className="buttons-container">
          <a href="https://res.cloudinary.com/appmasters-io/raw/upload/v1666987808/vcard_tiago_gouvea_41d52be0e5_d880949b13.vcf?updated_at=2022-10-28T20:10:08.848Z">
            <DownloadIcon /> Add to phone book
          </a>
          <a
            href="https://calendly.com/tiago-gouvea/app-masters-meeting"
            target="_blank"
            rel="noreferrer"
          >
            <FaCalendarAlt size={20} /> Schedule a meeting
          </a>
        </div>
        {/* <div className="share">
          <FiShare2 size={18} />
          Share Contact
        </div> */}
      </CardInfo>
    </Wrapper>
  );
};

export default ContactCard;
