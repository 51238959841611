import styled from 'styled-components';
import media from '../../../styles/media';

export const ServiceItemWrapper = styled.div<{ $inverted?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 64px 0;
  align-items: center;
  justify-content: center;
  text-align: left;

  ${media('medium')} {
    flex-direction: ${(props) => (props.$inverted ? 'row-reverse' : 'row')};
  }
  padding-bottom: 0;
  .image-container {
    width: 300px;
    height: 350px;
    width: 100%;
    height: 100%;

    ${media('medium')} {
      max-width: 500px;
      height: 350px;
      ${(props) => (props.$inverted ? 'margin-left: 20px' : 'margin-right: 20px')};
    }
  }

  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    ${media('medium')} {
      max-width: 500px;
      max-height: 350px;
    }
  }
  .img:hover {
    cursor: pointer;
  }

  .img-style {
    transition: all 200ms ease-in 0s;
    :hover {
      box-shadow: 0px 0px 10px 0px rgb(173, 173, 173, 0.5);
      transform: scale(1.01);
    }

    .border-radius {
      border-radius: 5px;
    }
  }

  > div:not(.img, .image-container) {
    ${media('medium')} {
      padding: 32px 0px;
    }

    h1 {
      line-height: 40px;
    }
    p {
      color: ${(props) => props.theme.colors.darkText};
      line-height: 1.8;
    }
    h1,
    p {
      text-align: left !important;
    }
  }
  div.title-list ul li {
    color: ${(props) => props.theme.colors.darkText};
  }
`;
