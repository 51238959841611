import Typography from '../Typography';
import { GlassDoorWrapper, BannerGlassDoor } from './styles';

export type GlassdoorProps = {
  title?: string;
  description?: string;
  image?: string;
};

const Glassdoor: React.FC<GlassdoorProps> = ({ title, description, image }) => {
  return (
    <GlassDoorWrapper>
      <BannerGlassDoor>
        {title && <Typography variant="h1">{title}</Typography>}
        {description && (
          <Typography variant="p" color="darkText">
            {description}
          </Typography>
        )}
        <a
          href="https://www.glassdoor.com.br/Visão-geral/Trabalhar-na-App-Masters-EI_IE3229619.13,24.htm"
          title="Glassdoor"
        >
          <img
            src={
              image ?? 'https://www.glassdoor.com.br/api/widget/horizontalStarRating.htm?e=3229619'
            }
            alt="Glassdoor"
            loading="lazy"
          />
        </a>
      </BannerGlassDoor>
    </GlassDoorWrapper>
  );
};

export default Glassdoor;
