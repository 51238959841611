import { AvailableJobItemWrapper } from './styles';
import Typography from '../Typography';

export type AvailableJobItemType = {
  title: string;
  isWorkRemote: boolean;
  slug: string;
  baseSalary: string;
};

export type AvailableJobItemProps = {
  title: string;
  isWorkRemote: boolean;
  slug: string;
  baseSalary: string;
};

const AvailableJobItem: React.FC<AvailableJobItemProps> = ({
  title,
  isWorkRemote,
  slug,
  baseSalary,
}) => {
  return (
    <AvailableJobItemWrapper>
      <div className="title-remote-style">
        <div>
          <a
            href={`https://programador.emjuizdefora.com/oportunidade/${slug}`}
            target="_blank"
            rel="noreferrer"
          >
            <Typography variant="h3" fontWeight="200">
              {title}
            </Typography>
          </a>
        </div>
        {isWorkRemote && (
          <div>
            <Typography variant="p" color="white">
              Trabalho Remoto
            </Typography>
          </div>
        )}
      </div>

      <div>
        <Typography variant="p" color="darkText">
          {baseSalary}
        </Typography>
      </div>
    </AvailableJobItemWrapper>
  );
};

export default AvailableJobItem;
