/* eslint-disable camelcase */
import DynamicZoneTemplate from './template';
import { DynamicZoneContent, StyledSection } from './styles';
import {
  AboutUsAboutDynamicDynamicZone,
  ComponentComponentsGlassdoor,
  ComponentComponentsDottedList,
  ComponentComponentsSection,
  ComponentComponentsTimeline,
  ComponentComponentsRichtext,
  ComponentComponentsTechnologies,
  ComponentComponentsGetInTouch,
  ComponentComponentsTextWithSideList,
  HomePageHomeDynamicDynamicZone,
  ProjectProjectsDynamicDynamicZone,
  ComponentComponentsDevelopmentDetails,
  Testimony,
  BlogBlogDynamicDynamicZone,
  ComponentComponentsGallery,
  ComponentComponentsBlog,
  UploadFile,
  ComponentComponentsButton,
  ComponentComponentsCardDisplay,
  ComponentComponentsService,
  Blog,
  PagePageDynamicDynamicZone,
  Technology,
  ComponentComponentsBanner,
  ComponentComponentsAvailableJobs,
  ComponentComponentsTestimonialDetail,
  ComponentComponentsTeamCalculator,
  ComponentComponentsBudgetForm,
  Enum_Componentcomponentsbudgetform_Background,
  ComponentComponentsUserTestimony,
  ComponentComponentsProjectsList,
  ComponentComponentsSectionImageRichText as ComponentComponentsSectionImageRichTextType,
  ComponentComponentsSectionQuote,
  ComponentComponentsHubspotMeeting,
  ComponentComponentsSectionCards,
  ComponentComponentsSectionTextColumns,
  ComponentComponentsSectionImageRichTextList,
  ComponentComponentsEventList,
  Event,
} from '../../../types/schema';
import { AvailableJobItemProps } from '../../ui/AvailableJobItem';
import Section, { ButtonDirection } from '../../ui/Section';
import Gallery, { GalleryProps } from '../../ui/Gallery';
import UserTestimony, { UserTestimonyProps } from '../../ui/UserTestimony';
import Timeline, { TimelineProps } from '../../ui/Timeline';
import DottedList, { DottedListProps } from '../../ui/DottedList';
import GetInTouch, { GetInTouchProps } from '../../ui/GetInTouch';
import TextWithSideList, { TextWithSideListProps } from '../../ui/TextWithSideList';
import Richtext, { RichtextProps } from '../../ui/Richtext';
import TechnologyGrid, { ButtonType, TechnologyGridProps } from '../../ui/TechnologyGrid';
import LandingForm, { LandingFormProps } from '../LandingForm';
import DevelopmentDetails, { DevelopmentDetailsProps } from '../../ui/DevelopmentDetails';
import React, { useCallback } from 'react';
import BannerTemplate, { BannerTemplateProps } from '../../ui/Banner';
import Glassdoor from '../../ui/Glassdoor';
import { formatSrcSet } from '../../../utils/formatURLs';
import AvailableJobs from '../AvailableJobs';
import UserTestimonyItem from '../../ui/UserTestimonyItem';
import { ServiceItemProps } from '../../ui/ServiceItem';
import BlogPostList from '../../ui/BlogPostList';
import { Card } from '../../ui/CardsDisplay';
import { WhereToFindType } from '../../ui/WhereToFind';
import TeamCalculator from '../../ui/TeamCalculator';
import BudgetForm from '../../ui/BudgetForm';
import ContactForm, { ContactFormProps } from '../../ui/ContactForm';
import ContactCard from '../../ui/ContactCard';
import { Project } from '../../../types/schema';
import ProjectItem from '../ProjectItem';
import { ProjectsList } from '../AllProjectsPage/styles';
import Typography from '../../ui/Typography';
import SectionImageRichText, { SectionImageRichTextProps } from '../../ui/SectionImageRichText';
import SectionQuote from '../../ui/SectionQuote';
import HubspotMeeting from '../../elements/HubspotMeeting';
import SectionBackgroundImageRichText, {
  SectionBackgroundImageRichTextProps,
} from '../../ui/SectionBackgroundImageRichText';
import { SectionCards, SectionCardsProps } from '../../ui/SectionCards';
import SectionTextButtons, { SectionTextButtonsProps } from '../../ui/SectionTextButtons';
import {
  getBackgroundColor,
  getBackgroundImage,
  getDZContainerStyles,
  getTextColor,
} from '../../../utils/dynamicZone';
import SectionTextColumns, { Content } from '../../ui/SectionTextColumns';
import SectionTitleItemList, { SectionTitleItemListProps } from '../../ui/SectionTitleItemList';
import SectionImageRichTextList from '../../ui/SectionImageRichTextList';
import { useRouter } from 'next/router';
import EventList from '../../ui/EventList';

// Grouping all DynamicZones
export type DynamicZoneType =
  | AboutUsAboutDynamicDynamicZone
  | HomePageHomeDynamicDynamicZone
  | ProjectProjectsDynamicDynamicZone
  | BlogBlogDynamicDynamicZone
  | PagePageDynamicDynamicZone;

/**
 * DynamicZoneOptionsType
 *
 * Code-gen will generate all Components types from strapi so for each Component type generated we type on this object
 * @property Some of the properties may need a conversion before passing the property to the component
 */
export type DynamicZoneOptionsType = {
  /**
   * ComponentComponentsSection
   * @param dynamicZone ComponentComponentsSection
   */
  ComponentComponentsSection: (homeDynamic: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsUserTestimony
   * @param dynamicZone ComponentComponentsUserTestimony
   */
  ComponentComponentsUserTestimony: (
    aboutDynamic: DynamicZoneType,
  ) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsDottedList
   * @param dynamicZone ComponentComponentsDottedList
   */
  ComponentComponentsDottedList: (aboutDynamic: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsTimeline
   * @param dynamicZone ComponentComponentsTimeline
   */
  ComponentComponentsTimeline: (timeline: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsForm
   * @param dynamicZone ComponentComponentsForm
   */
  ComponentComponentsForm: (landingDynamic: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsRichtext
   * @param dynamicZone ComponentComponentsRichtext
   */
  ComponentComponentsRichtext: (body: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsTechnologies
   * @param dynamicZone ComponentComponentsTechnologies
   */
  ComponentComponentsTechnologies: (technologies: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsGetInTouch
   * @param dynamicZone ComponentComponentsGetInTouch
   */
  ComponentComponentsGetInTouch: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsTextWithSideList
   * @param dynamicZone ComponentComponentsTextWithSideList
   */
  ComponentComponentsTextWithSideList: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsBlog
   * @param dynamicZone ComponentComponentsBlog
   */
  ComponentComponentsBlog: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsDevelopmentDetails
   * @param dynamicZone ComponentComponentsDevelopmentDetails
   */
  ComponentComponentsDevelopmentDetails: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsGallery
   * @param dynamicZone ComponentComponentsGallery
   */
  ComponentComponentsGallery: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsBanner
   * @param dynamicZone ComponentComponentsBanner
   */
  ComponentComponentsBanner: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsGlassdoor
   * @param dynamicZone ComponentComponentsGlassdoor
   */
  ComponentComponentsGlassdoor: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsAvalibleJobs
   * @param dynamicZone ComponentComponentsAvalibleJobs
   */
  ComponentComponentsAvailableJobs: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsTestimonialDetail
   * @param dynamicZone ComponentComponentsTestimonialDetail
   */
  ComponentComponentsTestimonialDetail: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsTeamCalculator
   * @param dynamicZone ComponentComponentsTeamCalculator
   */
  ComponentComponentsTeamCalculator: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsBudgetForm
   * @param dynamicZone ComponentComponentsBudgetForm
   */
  ComponentComponentsBudgetForm: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsContactForm
   * @param dynamicZone ComponentComponentsContactForm
   */
  ComponentComponentsContactForm: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsCompanyForm
   * @param dynamicZone ComponentComponentsCompanyForm
   */
  ComponentComponentsCompanyForm: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsContactCard
   * @param dynamicZone ComponentComponentsContactCard
   */
  ComponentComponentsContactCard: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsProjectsList
   * @param dynamicZone ComponentComponentsProjectsList
   */
  ComponentComponentsProjectsList: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsSectionImageRichText
   * @param dynamicZone ComponentComponentsSectionImageRichText
   */
  ComponentComponentsSectionImageRichText: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsSectionBackgroundImageRichText
   * @param dynamicZone ComponentComponentsSectionBackgroundImageRichText
   */
  ComponentComponentsSectionBackgroundImageRichText: (
    data: DynamicZoneType,
  ) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsSectionQuote
   * @param dynamicZone ComponentComponentsSectionQuote
   */
  ComponentComponentsSectionQuote: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsHubspotMeeting
   * @param dynamicZone ComponentComponentsHubspotMeeting
   */
  ComponentComponentsHubspotMeeting: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsSectionCards
   * @param dynamicZone ComponentComponentsSectionCards
   */
  ComponentComponentsSectionCards: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsSectionTextButtons
   * @param dynamicZone ComponentComponentsSectionTextButtons
   */
  ComponentComponentsSectionTextButtons: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsSectionTextColumns
   * @param dynamicZone ComponentComponentsSectionTextColumns
   */
  ComponentComponentsSectionTextColumns: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsSectionTitleItemList
   * @param dynamicZone ComponentComponentsSectionTitleItemList
   */
  ComponentComponentsSectionTitleItemList: (data: DynamicZoneType) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsSectionImageRichTextList
   * @param dynamicZone ComponentComponentsSectionImageRichTextList
   * @returns <SectionImageRichTextList />
   */
  ComponentComponentsSectionImageRichTextList: (
    dynamicZone: DynamicZoneType,
  ) => JSX.Element | React.ReactNode;
  /**
   * ComponentComponentsEventList
   * @param dynamicZone  ComponentComponentsEventList
   * @returns <EventList/>
   */
  ComponentComponentsEventList: (dynamicZone: DynamicZoneType) => JSX.Element | React.ReactNode;
};

/**
 * convertImageProperty
 *
 * @param obj object to be converted
 * @param name name of the image property on the new object
 * @param imageName name of the image property on the current object
 * @returns a new object with the property name configured
 */
const convertImageProperty = (obj: unknown, name: string, imageName?: string) => {
  return {
    ...(obj as Record<string, unknown>),
    [imageName || 'image']: (obj as { [key: string]: { data: { attributes: { url: string } } } })?.[
      name
    ]?.data?.attributes,
  };
};

export type DynamicZonesProps = {
  dynamicZones: DynamicZoneType[];
  language?: string;
  asPath?: string;
  contentStyle?: {
    paddingTop?: number;
    paddingBottom?: number;
  };
  allTechnologies?: Technology[];
  allTestimonies?: Testimony[];
  allEvents?: Event[];
  lastBlogs?: Blog[];
  techsInsideContainer?: boolean;
  jobs?: AvailableJobItemProps[];
  page?: string;
  isCustomPage?: boolean;
};

/**
 * DynamicZone
 */
const DynamicZones: React.FC<DynamicZonesProps> = ({
  dynamicZones,
  contentStyle,
  language,
  asPath,
  allTechnologies,
  allEvents,
  allTestimonies,
  lastBlogs,
  techsInsideContainer,
  jobs,
  page,
  isCustomPage,
}) => {
  const router = useRouter();

  /**
   * DynamicZoneOptions
   *
   * This object will determine which element should be rendered based on the __typename returned
   * For each new component created on Components section in strapi is required to add here if don't the component will no appear
   * @returns the JSX element based on the current DynamicZone Component from strapi
   */

  const dynamicZoneOptions: DynamicZoneOptionsType = {
    ComponentComponentsSection: (dynamicZone) => {
      const section = dynamicZone as ComponentComponentsSection;
      const { cardDisplay, service } = section;
      const buttons = section.button || [];

      const gallery = (section.gallery?.data || []).map((gallery) =>
        formatSrcSet(gallery.attributes as UploadFile, 'gallery'),
      );

      const cloudinaryOriginalURLs = (section.gallery?.data || []).map(
        (gallery) => gallery?.attributes?.url,
      );

      const cards = (cardDisplay?.cards || []).map((card) => convertImageProperty(card, 'image'));

      const serviceItens = (service?.serviceItens || []).map((service) => {
        if (service?.image)
          return {
            ...service,
            image: formatSrcSet(service?.image?.data?.attributes as UploadFile, '', {
              resize: {
                type: 'fit',
                width: '700',
                height: '612',
              },
              quality: 70,
            }),
            cloudinaryOriginalURL: service?.image?.data?.attributes?.url,
          };
        return {
          ...service,
          image: '',
          cloudinaryOriginalURL: service?.image?.data?.attributes?.url,
        };
      });
      const isJustSubtitle =
        serviceItens.length === 0 && cards.length === 0 && gallery.length === 0;
      return (
        <Section
          buttons={buttons as ComponentComponentsButton[]}
          buttonDirection={section.buttonDirection as ButtonDirection}
          gallery={gallery as string[]}
          cloudinaryOriginalURLs={cloudinaryOriginalURLs as string[]}
          galleryImagePosition={section.galleryImagePosition as 'covered' | 'contained'}
          cardDisplay={cardDisplay as ComponentComponentsCardDisplay}
          cards={cards as Card[]}
          service={service as ComponentComponentsService}
          serviceItens={serviceItens as ServiceItemProps[]}
          titleBold={!!section.titleBold}
          title={section.title as string}
          subtitle={section.subtitle as string}
          isJustSubtitle={isJustSubtitle as boolean}
        />
      );
    },
    ComponentComponentsUserTestimony: (dynamicZone) => {
      const userTestimony = dynamicZone as ComponentComponentsUserTestimony;
      const testimonies = (userTestimony.testimonies?.data || []).map((testimony) =>
        convertImageProperty(testimony.attributes, 'image'),
      );
      const allTest = (allTestimonies || []).map((testimony) =>
        convertImageProperty(testimony, 'image'),
      );

      const isTestimonySelected = testimonies.length > 0;
      // delete userTestimony.testimonies;
      return (
        <UserTestimony
          {...(userTestimony as unknown as UserTestimonyProps)}
          testimonies={(isTestimonySelected ? testimonies : allTest) as Testimony[]}
          isTestimonySelected={isTestimonySelected}
        />
      );
    },
    ComponentComponentsDottedList: (dynamicZone) => {
      const dottedList = dynamicZone as ComponentComponentsDottedList;
      return <DottedList {...(dottedList as DottedListProps)} />;
    },
    ComponentComponentsTimeline: (dynamicZone) => {
      const timeline = dynamicZone as ComponentComponentsTimeline;
      const gallery = (timeline.gallery?.data || []).map((gallery) => gallery?.attributes?.url);
      return <Timeline {...(timeline as TimelineProps)} gallery={gallery as string[]} />;
    },
    ComponentComponentsForm: (dynamicZone) => {
      const landingForm = dynamicZone as LandingFormProps;
      return <LandingForm {...landingForm} locale={language as string} asPath={asPath as string} />;
    },
    ComponentComponentsRichtext: (dynamicZone) => {
      const content = dynamicZone as ComponentComponentsRichtext;
      return (
        <Richtext {...(content as RichtextProps)} image={content.image?.data?.attributes?.url} />
      );
    },
    ComponentComponentsTechnologies: (dynamicZone) => {
      const technologies = {
        ...dynamicZone,
        type: (dynamicZone as unknown as { typeComponentComponentsTechnologies: string })
          .typeComponentComponentsTechnologies,
      } as ComponentComponentsTechnologies;
      let techs = (technologies.technologies?.data || [])?.map((tech) => {
        if (tech.attributes)
          return {
            id: tech.id,
            ...tech.attributes,
          };
        return tech;
      }) as Technology[];

      const type = technologies.type;

      if (!techs.length) {
        if (type) {
          techs =
            allTechnologies?.filter((tech) => (tech.type as string) === (type as string)) || [];
        } else {
          techs = allTechnologies || [];
        }
      }

      return (
        <TechnologyGrid
          {...(technologies as unknown as TechnologyGridProps)}
          technologies={techs}
          buttonType={technologies.button?.type as ButtonType}
          buttonTitle={technologies.button?.title as string}
          buttonId={technologies.button?.id as string}
          techsInsideContainer={techsInsideContainer}
          buttonAction={technologies.button?.action as string}
          locale={language as string}
        />
      );
    },
    ComponentComponentsGetInTouch: (dynamicZone) => {
      const data = dynamicZone as ComponentComponentsGetInTouch;
      return <GetInTouch {...(data as GetInTouchProps)} language={language as string} />;
    },
    ComponentComponentsTextWithSideList: (dynamicZone) => {
      const data = dynamicZone as ComponentComponentsTextWithSideList;
      return <TextWithSideList {...(data as TextWithSideListProps)} />;
    },
    ComponentComponentsDevelopmentDetails: (dynamicZone) => {
      const { whereToFind, ...data } = dynamicZone as ComponentComponentsDevelopmentDetails;
      const convertedWhereToFind = whereToFind?.map((data) => convertImageProperty(data, 'image'));
      return (
        <DevelopmentDetails
          {...(data as DevelopmentDetailsProps)}
          whereToFind={convertedWhereToFind as WhereToFindType[]}
          locale={language as string}
        />
      );
    },
    ComponentComponentsGallery: (dynamicZone) => {
      const { gallery: images, ...data } = dynamicZone as ComponentComponentsGallery;
      const gallery = (images?.data || []).map((image) =>
        image ? formatSrcSet(image.attributes as UploadFile, 'gallery') : '',
      );
      const cloudinaryOriginalURLs = (images?.data || []).map((image) => image?.attributes?.url);
      const obj = { images: gallery, ...data, cloudinaryOriginalURLs };
      return <Gallery {...(obj as GalleryProps)} />;
    },
    ComponentComponentsBlog: (dynamicZone) => {
      const { title, post, limit } = dynamicZone as ComponentComponentsBlog;
      const blogPosts = lastBlogs?.slice(0, limit || 1);
      return (
        (post || blogPosts) && (
          <BlogPostList
            lastBlogs={blogPosts}
            post={post?.data?.attributes as Blog}
            title={title as string}
          />
        )
      );
    },
    ComponentComponentsBanner: (dynamicZone) => {
      const { image, type, description, title } = dynamicZone as ComponentComponentsBanner;
      return (
        <BannerTemplate
          bannerImg={image?.data?.attributes?.url as string}
          {...({ type, description, title } as BannerTemplateProps)}
        />
      );
    },
    ComponentComponentsGlassdoor: (dynamicZone) => {
      const data = dynamicZone as ComponentComponentsGlassdoor;
      return (
        <Glassdoor
          title={data?.title as string}
          description={data?.description as string}
          image={data.image?.data?.attributes?.url as string}
        />
      );
    },
    ComponentComponentsAvailableJobs: (dynamicZones) => {
      const data = dynamicZones as ComponentComponentsAvailableJobs;
      return (
        <AvailableJobs title={data.title} description={data.description as string} jobs={jobs} />
      );
    },
    ComponentComponentsTestimonialDetail: (dynamicZones) => {
      const data = dynamicZones as ComponentComponentsTestimonialDetail;
      return (
        <UserTestimonyItem
          name={data.name as string}
          companyName={data.companyName as string}
          image={data.image?.data?.attributes as UploadFile}
          occupation={data.occupation as string}
          type={data.type as string}
          testimony={data.testimony as string}
          clientCountry={data.clientCountry as string}
          compact={true}
          cardBackground={data.cardBackground || 'lightGradient'}
        />
      );
    },
    ComponentComponentsTeamCalculator: (dynamicZones) => {
      const _data = dynamicZones as ComponentComponentsTeamCalculator;
      return <TeamCalculator />;
    },
    ComponentComponentsBudgetForm: (dynamicZones) => {
      const data = dynamicZones as ComponentComponentsBudgetForm;
      return (
        <BudgetForm background={data.background as Enum_Componentcomponentsbudgetform_Background} />
      );
    },
    ComponentComponentsContactForm: (dynamicZones) => {
      const data = dynamicZones as ContactFormProps;
      return <ContactForm {...data} />;
    },
    ComponentComponentsCompanyForm: (dynamicZones) => {
      const data = dynamicZones as ContactFormProps;
      return <ContactForm {...data} isCompany />;
    },
    ComponentComponentsContactCard: (_dynamicZones) => {
      return <ContactCard />;
    },
    ComponentComponentsProjectsList: (_dynamicZones) => {
      const { projects, filter, background, title, description } = {
        ..._dynamicZones,
      } as ComponentComponentsProjectsList & {
        projects: Project[];
      };
      return (
        <StyledSection background={background || undefined}>
          {(title || description) && (
            <div className="text-box">
              {title && (
                <Typography variant="h2" fontSize="48px">
                  {title}
                </Typography>
              )}
              {description && (
                <Typography variant="p" fontSize="18px">
                  {description}
                </Typography>
              )}
            </div>
          )}
          <ProjectsList
            isDynamic
            background={background || undefined}
            projectsLength={projects.length}
          >
            {projects.map((project) => (
              <ProjectItem
                isDynamic
                featured={filter === 'featured'}
                thumbnailImage={formatSrcSet(
                  project.thumbnailImage?.data?.attributes as UploadFile,
                  'projectThumbnail',
                )}
                language={language || ''}
                key={project.slug}
                project={project}
              />
            ))}
          </ProjectsList>
        </StyledSection>
      );
    },
    ComponentComponentsSectionImageRichText: (dynamicZones) => {
      const data = dynamicZones as ComponentComponentsSectionImageRichTextType;
      return (
        <SectionImageRichText
          {...(data as unknown as SectionImageRichTextProps)}
          image={data?.image?.data?.attributes?.url as string}
        />
      );
    },
    ComponentComponentsSectionQuote: (dynamicZones) => {
      const { text } = dynamicZones as ComponentComponentsSectionQuote;
      return <SectionQuote text={text || ''} />;
    },
    ComponentComponentsHubspotMeeting: (dynamicZones) => {
      const { meetingSlug } = dynamicZones as ComponentComponentsHubspotMeeting;
      return <HubspotMeeting slug={meetingSlug || ''} />;
    },
    ComponentComponentsSectionBackgroundImageRichText: (dynamicZones) => {
      return (
        <SectionBackgroundImageRichText
          {...(dynamicZones as unknown as SectionBackgroundImageRichTextProps)}
        />
      );
    },
    ComponentComponentsSectionCards: (dynamicZones) => {
      const { actionLink, backgroundColor, cardsPosition, content, sectionCard } =
        dynamicZones as ComponentComponentsSectionCards;

      const cards = sectionCard?.map((card) => ({
        ...card,
        icon: card?.icon?.data?.attributes?.url,
      }));

      return (
        <SectionCards
          {...({
            backgroundColor,
            cardsPosition,
            content,
            cards,
            callToAction: actionLink,
          } as SectionCardsProps)}
        />
      );
    },
    ComponentComponentsSectionTextButtons: (dynamicZones) => {
      return <SectionTextButtons {...(dynamicZones as unknown as SectionTextButtonsProps)} />;
    },
    ComponentComponentsSectionTextColumns: (dynamicZones) => {
      const { content } = dynamicZones as ComponentComponentsSectionTextColumns;
      return <SectionTextColumns content={(content || []) as Content[]} />;
    },
    ComponentComponentsSectionTitleItemList: (dynamicZones) => {
      return <SectionTitleItemList {...(dynamicZones as unknown as SectionTitleItemListProps)} />;
    },
    ComponentComponentsSectionImageRichTextList: (dynamicZones) => {
      return (
        <SectionImageRichTextList
          {...(dynamicZones as ComponentComponentsSectionImageRichTextList)}
        />
      );
    },
    ComponentComponentsEventList: (dynamicZones) => {
      const props = dynamicZones as unknown as ComponentComponentsEventList;
      return <EventList {...{ events: allEvents || [], ...props }} />;
    },
  };

  // This method is used by old components. Should be removed soon
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const background = useCallback((item: any) => {
    if (item?.__typename !== 'ComponentComponentsGallery') {
      return item.background;
    }
    return undefined;
  }, []);

  const generateId = useCallback(
    (type: DynamicZoneType['__typename'], index: number) =>
      type ? type?.replace('ComponentComponents', '') : `${index}`,
    [],
  );

  const isProject =
    router.pathname === '/projetos/[slug]' || router.pathname === '/projects/[slug]';

  return (
    <DynamicZoneTemplate>
      {dynamicZones.map((item, index) => {
        return item.__typename === 'ComponentComponentsTechnologies' &&
          !item.technologies?.data.length &&
          isProject ? null : (
          <DynamicZoneContent
            id={generateId(item.__typename, index)}
            key={`dynamic-zone-${item.__typename}${index}`}
            background={background(item)}
            isBanner={item.__typename === 'ComponentComponentsBanner'}
            isFirstComponent={index === 0}
            backgroundImage={getBackgroundImage(item)}
            backgroundColor={getBackgroundColor(item)}
            textColor={getTextColor(item)}
            isCustomPage={isCustomPage}
            style={getDZContainerStyles({ page, component: item })}
            {...contentStyle}
          >
            {dynamicZoneOptions[item.__typename as keyof DynamicZoneOptionsType](item)}
          </DynamicZoneContent>
        );
      })}
    </DynamicZoneTemplate>
  );
};

export default DynamicZones;
