import Link from 'next/link';
import React from 'react';

import { ProjectItemDetails, ProjectItemContainer, ProjectItemDescriptionWrapper } from './styles';
import { Project } from '../../../types/schema';

type ProjectItemProps = {
  project: Project;
  dontClickImage?: boolean;
  language: string;
  thumbnailImage: string;
  featured?: boolean;
  isDynamic?: boolean;
};

const ProjectItem: React.FC<ProjectItemProps> = (
  { project, dontClickImage, language, thumbnailImage, featured, isDynamic },
  i,
) => {
  const {
    title,
    // summary,
    companyName,
    clientLocation,
    slug,
    type,
  } = project;

  const description = featured ? project.featuredDescription : project.description;

  const isEn = language === 'en';

  return (
    <ProjectItemContainer key={i}>
      <div>
        {dontClickImage ? (
          <div className="image">
            <img srcSet={thumbnailImage as string} alt={title as string} loading="lazy" />
          </div>
        ) : (
          <Link
            href={isEn ? `/en/projects/${slug}/` : `/pt/projetos/${slug}/`}
            tabIndex={-1}
            title={
              isEn ? 'Go to project details page.' : 'Ir para a página de mais detalhes do projeto.'
            }
          >
            <div className="image">
              <img srcSet={thumbnailImage as string} alt={title as string} loading="lazy" />
            </div>
          </Link>
        )}
      </div>
      <ProjectItemDetails>
        {type !== 'sideproject' && !isDynamic ? (
          <div className="company-info">
            <div>
              <span>{companyName}</span>
              <span className="info">({clientLocation})</span>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <ProjectItemDescriptionWrapper>
          <Link
            href={isEn ? `/en/projects/${slug}/` : `/pt/projetos/${slug}/`}
            className="link-to-project-item"
            title={
              isEn ? 'Go to project details page.' : 'Ir para a página de mais detalhes do projeto.'
            }
          >
            <h2 itemProp="name">{title}</h2>
          </Link>
          <div className="description">
            <p>{(description || '').replace(/<[^>]*>?/gm, '')}</p>
          </div>
        </ProjectItemDescriptionWrapper>
      </ProjectItemDetails>
    </ProjectItemContainer>
  );
};

export default ProjectItem;
